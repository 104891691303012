import axios from 'axios';
import SkippedRequestsTypes from '../types/SkippedRequestsTypes'
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl'
import { ResultStatus } from '../../utils/AppConsts';
import SkipRequestModel from '../../models/SkipRequestModel';
import baseUrl from '../../Apis/apis';
import { SKIPPED_REQUESTS_LIMITED } from '../reducers/SkippedRequestsReducer';
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../types/CurrentUserTypes';


const create = (
    reasonToSkip: string,
    employee: string,
    compnay: string,
    date: string,
    loan: string,
    navigator: NavigateFunction, intl: IntlShape) => {

    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');


            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/create", {
                "params": {
                    "args": {
                        "vals": {
                            "state": "draft",
                            "name": reasonToSkip,
                            "loan_id": loan,
                            "employee_id": employee,
                            "company_id": compnay,
                            "date": date,
                            "message_follower_ids": [],
                            "message_ids": []
                        }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    navigator(-1);
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}





const update = (
    skipRequestId: any,
    reasonToSkip: string,
    employee: string,
    compnay: string,
    date: string,
    loan: string,
   intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/write", {
                "params": {
                    "id": Number(skipRequestId),
                    "args": {
                        "vals": {
                            "state": "draft",
                            "name": reasonToSkip,
                            "loan_id": loan,
                            "employee_id": employee,
                            "company_id": compnay,
                            "date": date,
                            "message_follower_ids": [],
                            "message_ids": []
                        }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });
            console.log("hi all" + JSON.stringify(res.data));


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getSkippedRequestById(skipRequestId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                   
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}




const cancelSkippedRequest = (
    skippedRequestId: string,
    navigator: NavigateFunction,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/set_to_cancel", {
                "params": {
                    "id": Number(skippedRequestId)

                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        navigator(-1);
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}



const deleteSkippedRequest = (
    skippedRequestId: string,
    navigator: NavigateFunction,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');


            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/unlink", {
                "params": {
                    "id": Number(skippedRequestId)
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });





            if (res.status == ResultStatus.ok) {
                const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const result = res.data['result'];
                    if (result['result'] == true) {
                     navigator(-1);
                    }else{
                alert(intl.formatMessage({ id: 'tryAgain' }));
                        }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}




const confirmSkippedRequest = (
    skippedRequestId: string,
   intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/confirm_request", {
                "params": {
                    "id": Number(skippedRequestId)

                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getSkippedRequestById(skippedRequestId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                   
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}




const getSkippedRequests = (
    intl: IntlShape, pageNumber: number) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');


            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["name", "employee_id", "loan_id", "date", "state"],
                            "domain": [],
                            "limit": SKIPPED_REQUESTS_LIMITED,
                            "offset": pageNumber * SKIPPED_REQUESTS_LIMITED
                        }
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const result = res.data;

                    const list = result['result'] ?? [];

                    var skippedRequests: SkipRequestModel[] = [];
                    list.map((item: any) => {
                        const skipRequest = SkipRequestModel.fromJson({
                            id: item.id,
                            employee: item.employee_id,
                            loan: item.loan_id == false ? undefined : item.loan_id[1],
                            reasonToSkip: item.name,
                            date: item.date,
                            status: item.state,

                        });
                        skippedRequests.push(skipRequest);
                    });

                    dispatch({
                        type: SkippedRequestsTypes.setSkippedRequests, data: {
                            pageNumber,
                            skippedRequests
                        }
                    });

                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}



const getSkippedRequestById =  (
    skippedRequestId: string,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["create_uid","create_date","write_uid","write_date","name", "employee_id", "company_id", "loan_id", "date", "state"],
                            "domain": [["id", "=", skippedRequestId]],
                        }
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const result = res.data;
                    const list = result['result'] ?? [];

                    if (list.length != 0) {
                        const item = list[0];
                        const skipRequest = SkipRequestModel.fromJson({
                            id: item.id,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            loan: item.loan_id == false ? undefined : item.loan_id,
                            reasonToSkip: item.name,
                            date: item.date,
                            status: item.state,
                            createdBy : item.create_uid,
                            lastUpdatedBy : item.write_uid,
                            createdOn : item.create_date,
                            lastUpdatedOn : item.write_date,
                        });

                        dispatch({ type: SkippedRequestsTypes.selectSkippedRequest, data: skipRequest });
                    }

                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }


        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}



const getSkippedRequestsCount = (
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.skip.installment/search_count",
                {
                    "params": {
                        "args": {
                            "args": []
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const count = result['result'] ?? 0;
                    dispatch({ type: SkippedRequestsTypes.setSkippedRequestsCount, data: count });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}




export const SkippedRequestsActions = {
    create,
    update,
    getSkippedRequests,
    getSkippedRequestById,
    getSkippedRequestsCount,
    cancelSkippedRequest,
    deleteSkippedRequest,
    confirmSkippedRequest
};