import { number } from "yup";
import LoanModel, { LoanInterface } from "../../models/LoanModel";
import LoansTypes from "../types/LoansTypes";


interface LoansReducerInterface {
    selectedLoan: LoanModel | undefined,
    loans: LoanModel[],
    loansCount: number,
    pageNumber: number

}

export const LOANS_LIMITED = 8;

const initValue: LoansReducerInterface = {
    selectedLoan: undefined,
    loans: [],
    loansCount: 0,
    pageNumber: 0,

}

export const LoansReducer = (state = initValue, action: any) => {
    console.log(action.type + " -> " + JSON.stringify(action.data));

    switch (action.type) {
        case LoansTypes.setLoans:
            return { ...state, loans: action.data.loans, pageNumber: action.data.pageNumber };
        case LoansTypes.selectLoan:
            return { ...state, selectedLoan: action.data };
        case LoansTypes.addLoan:
            state.loans.push(action.data);
            return { ...state };
        case LoansTypes.setLoansCount:
            return { ...state, loansCount: action.data };
        default:
            return state;
    }
}