/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'
import { AuthPage } from '../modules/auth'
import Cookies from 'universal-cookie';
import { useAppDispatch, useAppSelector } from '../../redux/Hook/hook'
import { useDispatch } from 'react-redux'
import { CurrerUserActions } from '../../redux/actions/CurrentUserActions'
import { useIntl } from 'react-intl'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  
  const store = useAppSelector((state) => state);

  var currentUser = store.currentUserReducer.profile;
  const cookies = new Cookies();
  const serverUrl =   cookies.get('domain');
  const sessionId =   cookies.get('session_id');


  const dispatch = useAppDispatch();

  useEffect(()=>{
    var userId = cookies.get('userId');
    if(serverUrl != undefined &&   serverUrl != "" && sessionId != null && sessionId != "")
    dispatch(CurrerUserActions.getPersonalInformation(userId,undefined));
  },[])
  
  return ( 
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {serverUrl != undefined &&   serverUrl != "" && sessionId != null && sessionId != ""? (
          <>
          <Route path='/*' element={<PrivateRoutes />} />
          <Route index element={<Navigate to='/dashboard' />} />
        </>
      ) : (
        <>
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='*' element={<Navigate to='/auth' />} />
        </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
