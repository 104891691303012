export interface PayslipInterface  {
    id?: number,
    reference?: string,
    employee?: any[]| undefined,
    department?: any[]| undefined,
    batch?: any[]| undefined,
    company?:any[]| undefined,
    structure?:any[]| undefined,
    contract?:any[]| undefined,
    transportAllowance?: string,
    subsistence?: string,
    basicWage?: string,
    netWage?: string,
    subsistenceAllowance?: string,
    otherAllowances?: string,
    superviseAllowance?: string,
    washingAllowance?: string,
    otherDeductions?: string,
    status?: string,
    dateFrom?: string,
    dateTo?: string,
}

class PayslipModel {
    public id?: number;
    public reference?: string;
    public structure?: any[]| undefined;
    public contract?: any[]| undefined;
    public basicWage?: string;
    public netWage?: string;
    public employee?: any[]| undefined;
    public department?: any[]| undefined;
    public batch?: any[]| undefined;
    public company?: any[]| undefined;
    public transportAllowance?: string;
    public subsistence?: string;
    public subsistenceAllowance?: string;
    public otherAllowances?: string;
    public superviseAllowance?: string;
    public washingAllowance?: string;
    public otherDeductions?: string;
    public status?: string;
    public dateFrom?: string;
    public dateTo?: string;

    
    
    public static fromJson(json: PayslipInterface): PayslipModel {
        const payslip = new PayslipModel();
        payslip.id = json.id;
        payslip.reference = json.reference;
        payslip.employee = json.employee;
        payslip.structure = json.structure;
        payslip.contract = json.contract;
        payslip.department = json.department;
        payslip.batch = json.batch;
        payslip.basicWage = json.basicWage;
        payslip.company = json.company;
        payslip.transportAllowance = json.transportAllowance;
        payslip.subsistence = json.subsistence;
        payslip.basicWage = json.basicWage;
        payslip.netWage = json.netWage;
        payslip.subsistenceAllowance = json.subsistenceAllowance;
        payslip.otherAllowances = json.otherAllowances;
        payslip.superviseAllowance = json.superviseAllowance;
        payslip.washingAllowance = json.washingAllowance;
        payslip.otherDeductions = json.otherDeductions;
        payslip.status = json.status;
        payslip.dateFrom = json.dateFrom;
        payslip.dateTo = json.dateTo;
        return payslip;
    }


public get statusColor(): string {
    switch (this.status) {
        case 'draft':
            return 'badge badge-secondary';
        case 'close':
            return 'badge badge-dark';
        case 'open':
            return 'badge badge-primary';
        case 'pause':
            return 'badge badge-warning';
        case 'cancel':
            return 'badge badge-danger';
            case 'verify':
                return 'badge badge-success';
              
        default:
            return 'badge badge-dark';
    }
}
}



export default PayslipModel;