export interface CustodyInterface {
     id?: number;
     requestData?: string;
     deliverDate?: string;
     status?: string;
     employee?: any[] | undefined;
     department?: any[] | undefined;
     company?: any[] | undefined;
}

class CustodyModel {
    public id?: number;
    public requestData?: string;
    public deliverDate?: string;
    public status?: string;
    public employee?: any[] | undefined;
    public department?: any[] | undefined;
    public company?: any[] | undefined;



    public static fromJson(json: CustodyInterface): CustodyModel {
        const vacation = new CustodyModel();
        vacation.id = json.id;
        vacation.status = json.status;
        vacation.requestData = json.requestData;
        vacation.deliverDate = json.deliverDate;
        vacation.employee = json.employee;
        vacation.company = json.company;
        vacation.department = json.department;
        vacation.status = json.status;
        return vacation;
    }

    public get statusColor(): string {
        switch (this.status) {
            case 'draft':
                return 'badge badge-secondary';
            case 'confirm':
                return 'badge badge-success';
            case 'open':
                return 'badge badge-info';
            case 'refuse':
                return 'badge badge-danger';
            case 'approve':
                return 'badge badge-dark';
            case 'done':
                return 'badge badge-light';
                case 'cancel':
            return 'badge badge-danger';
            default:
                return 'badge badge-dark';
        }
    }

}


export default CustodyModel;