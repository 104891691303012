/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, LogModel } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import LoanModel from '../../../../models/LoanModel'
import PayslipModel from '../../../../models/PayslipModel'
import { PayslipsActions } from '../../../../redux/actions/PayslipsActions'
import ListPagenation from '../pagenation/ListPagenation'
import { PAYSLIPS_LIMITED } from '../../../../redux/reducers/PayslipsReducer'
import { Button } from 'react-bootstrap'
type Props = {
  className: string
}

export const EmployeePayslipsTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch()

 const pageNavigatorHandler = (data:any)=>{
  
  dispatch(PayslipsActions.getPayslips(intl,data.selected));
}

  useEffect(() => {
    dispatch(PayslipsActions.getPayslipsCount(intl,));
    dispatch(PayslipsActions.getPayslips(intl,store.payslipsReducer.pageNumber));
  }, [])

  const printPayslip = (payrollId:any)=>{
    dispatch(PayslipsActions.printPayroll(payrollId,intl))
  }


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'employeePayslips' })} {store.payslipsReducer.payslipsCount > 0 ?' : '+ store.payslipsReducer.payslipsCount : ''} </span>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'employeePayslips' })} </span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a loan'
        >
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>{intl.formatMessage({ id: 'reference' })} </th>
                <th>{intl.formatMessage({ id: 'employee' })} </th>
                <th>{intl.formatMessage({ id: 'batchName' })}</th>
                <th>{intl.formatMessage({ id: 'company' })}</th>
                <th>{intl.formatMessage({ id: 'basicWage' })}</th>
                <th>{intl.formatMessage({ id: 'netWage' })}</th>
                <th>{intl.formatMessage({ id: 'status' })}</th>
                <th className='text-end'>{intl.formatMessage({ id: 'operations' })}</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
               store.payslipsReducer.payslips.map((payslip:PayslipModel,index:any) =>{
                  return (
                    
                    <tr key={payslip.reference == "" ||payslip.reference == undefined? index : payslip.reference}>
                      <td>{payslip.reference == "" ||payslip.reference == undefined? "-" : payslip.reference}</td>
                      <td>
                        <div className='d-flex align-items-center'>

                          <div className='d-flex justify-content-start flex-column'>
                            {payslip.employee == undefined? "" : payslip.employee[1]}
                          </div>
                        </div>
                      </td>
                      <td> {payslip.batch == undefined? "" : payslip.batch[1]}</td>
                      <td> {payslip.company??"-"}</td>
                      <td> {payslip.basicWage??"-"}</td>
                      <td> {payslip.netWage??"-"}</td>
                      <td>
                        <span className={payslip.statusColor}>{payslip.status}</span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                        <Button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' onClick={
                          (e)=>printPayslip(payslip.id)
                        }>
                        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
                        </Button>

                        </div>
                      </td>
                    </tr>
                   
                  );
                }

                )

              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ListPagenation pageCount={store.payslipsReducer.payslipsCount / PAYSLIPS_LIMITED} pageNavigatorHandler={pageNavigatorHandler}/>
    </div>
  )
}
