// charts
export * from './feeds/FeedsWidget2'
export * from './feeds/FeedsWidget3'
export * from './feeds/FeedsWidget4'
export * from './feeds/FeedsWidget5'
export * from './feeds/FeedsWidget6'

// mixed
export * from './mixed/MixedWidget1'
export * from './mixed/MixedWidget2'
export * from './mixed/MixedWidget3'
export * from './mixed/MixedWidget4'
export * from './mixed/MixedWidget5'
export * from './mixed/MixedWidget6'
export * from './mixed/MixedWidget7'
export * from './mixed/MixedWidget8'
export * from './mixed/MixedWidget9'
export * from './mixed/MixedWidget10'
export * from './mixed/MixedWidget11'

// lists
export * from './lists/ListsWidget1'
export * from './lists/ListsWidget2'
export * from './lists/ListsWidget3'
export * from './lists/ListsWidget4'
export * from './lists/ListsWidget5'
export * from './lists/ListsWidget6'
export * from './lists/ListsWidget7'
export * from './lists/ListsWidget8'

// statistics
export * from './statistics/StatisticsWidget1'
export * from './statistics/StatisticsWidget2'
export * from './statistics/StatisticsWidget3'
export * from './statistics/StatisticsWidget4'
export * from './statistics/StatisticsWidget5'
export * from './statistics/StatisticsWidget6'

// charts
export * from './charts/ChartsWidget1'
export * from './charts/ChartsWidget2'
export * from './charts/ChartsWidget3'
export * from './charts/ChartsWidget4'
export * from './charts/ChartsWidget5'
export * from './charts/ChartsWidget6'
export * from './charts/ChartsWidget7'
export * from './charts/ChartsWidget8'

// tables
export * from './tables/SkipInstallmentRequestTable'
export * from './tables/LoansTable'

// engage
export * from './engage/EngageWidget1'
export * from './engage/EngageWidget2'
export * from './engage/EngageWidget3'
export * from './engage/EngageWidget5'

// stats
export * from './stats/StatsWidget1'
export * from './stats/StatsWidget2'
export * from './stats/StatsWidget3'
export * from './stats/StatsWidget4'
export * from './stats/StatsWidget5'
export * from './stats/StatsWidget6'
export * from './stats/StatsWidget7'
export * from './stats/StatsWidget8'
export * from './stats/StatsWidget9'

// feeds
export * from './feeds/FeedsWidget2'
export * from './feeds/FeedsWidget3'
export * from './feeds/FeedsWidget4'
