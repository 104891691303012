import axios from 'axios';
import CurrentUserTypes from '../types/CurrentUserTypes'
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl'
import UserModel from '../../models/UserModel';
import { AppDispatch } from '../store/Store';
import { ResultStatus } from '../../utils/AppConsts';
import ConfigsTypes from '../types/ConfigsTypes';
import baseUrl from '../../Apis/apis';
import { Cookies } from 'react-cookie';




const getAuthApps = (intl: IntlShape) => {
    console.log("getAuthApps . . . . . .");

    return async (dispatch: AppDispatch) => {
        try {
            console.log("databases api ");
            const cookies = new Cookies();
            var serverUrl = cookies.get('domain');

            if (serverUrl != "" && serverUrl != undefined) {
                const res = await baseUrl.post("https://odoosmtapp.smt.sa/get_apps/",
                {
                  "params": { 
                    "domain": serverUrl,
                    "type": "web"
                
                }
                }, {
                headers: {
                  'Accept': '*/*',
                  'Content-Type': 'application/json',
                },
              }
            );

                if (res.status == ResultStatus.ok) {
                    const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

                    if (errorMessage) {
                        alert(errorMessage);
                        if (errorMessage.includes("Odoo Session Expired")) {
                            dispatch({ type: CurrentUserTypes.signout })
                        }
                    } else {
                        if (result) {
                            dispatch({ type: ConfigsTypes.setAuth, data: result['result'] });
                        } else {
                            alert(intl.formatMessage({ id: 'tryAgain' }));
                        }
                    }
                } else {
                    alert(intl.formatMessage({ id: 'tryAgain' }));
                }
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}




const getDatabases = (intl: IntlShape) => {
    console.log("getDatabases . . . . . .");

    return async (dispatch: AppDispatch) => {
        try {
            console.log("databases api ");
            const cookies = new Cookies();
            var serverUrl = cookies.get('domain');

            if (serverUrl != "" && serverUrl != undefined) {
                const res = await baseUrl.post(serverUrl + '/apiV2/database/list', {
                    "params": {
                    }
                }, {
                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                    },
                });

                if (res.status == ResultStatus.ok) {
                    const result = res.data;
                    var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

                    if (errorMessage) {
                        alert(errorMessage);
                        if (errorMessage.includes("Odoo Session Expired")) {
                            dispatch({ type: CurrentUserTypes.signout })
                        }
                    } else {
                        if (result) {
                            var dbs: string[] = [];
                            (result['result'] as []).map((db) => {
                                dbs.push(db);
                            });
                            dispatch({ type: ConfigsTypes.setDatabases, data: dbs });
                        } else {
                            alert(intl.formatMessage({ id: 'tryAgain' }));
                        }
                    }
                } else {
                    alert(intl.formatMessage({ id: 'tryAgain' }));
                }
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}



export const ConfigsActions = {
    getDatabases,
    getAuthApps
};