import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../redux/Hook/hook'
import { SkippedRequestsActions } from '../../redux/actions/SkippedRequestsActions'
import { useNavigate } from 'react-router-dom'
import { ResultStatus } from '../../utils/AppConsts'
import baseUrl from '../../Apis/apis'
import SkipRequestModel from '../../models/SkipRequestModel'
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../../redux/types/CurrentUserTypes'
import { AccessDenied } from './AccessDenied'

export function SkipInstallmentForm() {
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const isCreateOpe = window.location.pathname.includes('/create');

  let skipRequestId = "-1";
  if(!isCreateOpe){
    var index = window.location.pathname.lastIndexOf('/');
    skipRequestId = window.location.pathname.substring(index + 1);
  }

  const store = useAppSelector((state) => state);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();

  const [isGettingSelected, setIsGettingSelected] = useState(false)
  const [isGettingDefaultValues, setIsGettingDefaultValues] = useState(false);
  const [isGettingLookup, setIsGettingLookup] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCanEditting, setIsCanEditting] = useState(isCreateOpe);
  const [loans, setLoans] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState([]);
  const [isCanceling, setIsCanceling] = useState(false)



  const skipSchema = Yup.object().shape({
    reasonToSkip: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'reasonToSkip' })),
    employee: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'employeeName' })
      })),
    date: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'date' })
      })),
    company: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'company' })
      })),
    loan: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'loan' })
      })),
  })


  const cancelRequest = async()=>{
    setIsCanceling(true);
    await dispatch(SkippedRequestsActions.cancelSkippedRequest(skipRequestId,navigator,intl));
    setIsCanceling(false);
  }


  const initialValues = {
    reasonToSkip: '',
    employee: '',
    date: '',
    company: '',
    loan: '',
  }

  const cookies = new Cookies();
  var sessionId =   cookies.get('session_id');

  const formik = useFormik({
    initialValues,
    validationSchema: skipSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {

        if (isCreateOpe) {
           await dispatch(SkippedRequestsActions.create(values.reasonToSkip, values.employee, values.company, values.date, values.loan, navigator, intl));

        } else {
          await dispatch(SkippedRequestsActions.update(skipRequestId,values.reasonToSkip, values.employee, values.company, values.date, values.loan, intl));
        }


      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    },
  })



  const getDefaultValues = async () => {
    setIsGettingDefaultValues(true);
    try {
      console.log("databases api ");
      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');

      const res = await baseUrl.post(serverUrl+'/apiV2/hr.skip.installment/default_get', {
        "params":{
            "args":{
                "fields_list":["employee_id","date","company_id"]
            }
        }
    }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
                       var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

        if (errorMessage) {
            alert(errorMessage);
            if(errorMessage.includes("Odoo Session Expired")){
                dispatch({type:CurrentUserTypes.signout})
            }
        } else {
          const data = result['result'];
        if (data) {
          formik.values.employee = data['employee_id'];
          formik.values.company = data['company_id'];
          formik.values.date = data['date'];
        } else {
          alert(intl.formatMessage({ id: 'tryAgain' }));
        }
        }
    } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
    }



    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingDefaultValues(false);
  }

  

  const getLookup = async () => {
    setIsGettingLookup(true);
    try {
      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');

      const res = await baseUrl.post(serverUrl+'/apiV2/lookup/hr.skip.installment', {
        "params": {
            "lookups": [
              {
                "field_model": "state",
                "args": {
                    "fields": [
                        ""
                    ],
                    "domain": []
                }
            },
                {
                    "field_model": "employee_id",
                    "args": {
                        "fields": [
                            "display_name"
                        ],
                        "domain":  [],
                      
                        // "limit": 20
                    }
                },
                {
                    "field_model": "loan_id",
                    "args": {
                        "fields": [
                            "display_name"
                        ],
                        "domain":  [["employee_id","=",formik.values.employee],["state","=","approve"]],
                    }
                },
                {
                    "field_model": "company_id",
                    "args": {
                        "fields": [
                            "display_name"
                        ],
                        "domain": [],
                        // "limit": 20
                    }
                },
                {
                    "field_model": "approved_by",
                    "args": {
                        "fields": [
                            "display_name"
                        ],
                        "domain": [],
                        // "limit": 20
                    }
                },
                {
                    "field_model": "refused_by",
                    "args": {
                        "fields": [
                            "display_name"
                        ],
                        "domain": [],
                        // "limit": 20
                    }
                },
                {
                    "field_model": "state",
                    "args": {
                        "fields": [
                            ""
                        ],
                        "domain": []
                    }
                }
            ]
        }
    }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
                       var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

        if (errorMessage) {
            alert(errorMessage);
            if(errorMessage.includes("Odoo Session Expired")){
                dispatch({type:CurrentUserTypes.signout})
            }
        } else {
          const data = result['result'];
          if (data) {
            setEmployees(data['employee_id'] ?? []);
            setLoans(data['loan_id'] ?? []);
            setCompanies(data['company_id'] ?? []);
            setStatus((data['state'] ?? {})['selection'] ?? []);
  
          } else {
            alert(intl.formatMessage({ id: 'tryAgain' }));
          }
        }
    } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
    }


    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingLookup(false);
  }


  const confirmSkipRequest = async()=>{
    setIsConfirming(true);
    await dispatch(SkippedRequestsActions.confirmSkippedRequest(skipRequestId,intl));
    setIsConfirming(false);
  }
  
  const initPage = async ()=>{
    if(isCreateOpe){
      await  getDefaultValues();
    }else{
    await  dispatch(SkippedRequestsActions.getSkippedRequestById(skipRequestId, intl));
  }
  await getLookup();
  }

  useEffect(() => {
    initPage()
  }, [])
  

  // Set form values when  selected skip request geted
  useEffect(() => {
    setIsGettingSelected(true);
    if (!isCreateOpe) {
      const selectedSkippedRequest: SkipRequestModel | undefined = store.skippedRequestsReducer.selectedSkippedRequest;
      if (selectedSkippedRequest) {
        setIsCanEditting(selectedSkippedRequest.status == "draft");

        formik.values.reasonToSkip = selectedSkippedRequest.reasonToSkip ?? "";
        formik.values.employee = selectedSkippedRequest.employee == undefined ? "" : selectedSkippedRequest.employee[0];
        
        formik.values.company = selectedSkippedRequest.company == undefined ? "" : selectedSkippedRequest.company[0];
        formik.values.loan = selectedSkippedRequest.loan == undefined ? "" : selectedSkippedRequest.loan[0];
        formik.values.date = selectedSkippedRequest.date??"";
      }
    }
    setIsGettingSelected(false);
  }, [ store.skippedRequestsReducer.selectedSkippedRequest])




  if(!store.configsReducer.loansAuth){
    return <AccessDenied/>
  }


  return (
    <form
    className='form w-100'
    onSubmit={(e)=>{
      e.preventDefault();
      formik.handleSubmit(e);
    }}
    noValidate
    id='kt_skip_form'
  >

    <div className="card mb-5">
      <div className='col-sm-12 status'>
              {isCreateOpe ? "" : <h3 className='indicator-label  mb-0 d-flex justify-content-between align-items-center'>
                  <div>
                  {status.map((item)=><span className={(store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).status == item[0] ? 'text-primary active mx-5' : 'text-black-50 mx-5'}>   {item[1]} </span>)}
                  </div>
              </h3>}
      </div>
    </div>


    <div className='card card-custom card-flush'>
      <div className='card-header'>
        <h3 className='card-title'>{intl.formatMessage({ id: isCreateOpe? 'createSkip'  : 'showSkip'})}</h3>
        <div className='card-toolbar'>


        {isCanEditting ?
             <button
             type='submit'
             id='kt_sign_in_submit'
             className='btn btn-lg btn-primary mb-5 mt-4'
             disabled={formik.isSubmitting || !formik.isValid}
           >
             {!loading && <span className='indicator-label'>  {intl.formatMessage({ id: isCreateOpe? 'confirm' :  'update' })}</span>}
             {loading && (
               <span className='indicator-progress' style={{ display: 'block' }}>
                 {intl.formatMessage({ id: 'pleaseWait' })}
                 <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
               </span>
             )}
           </button>
              : ""}

            {isCanEditting && !isCreateOpe ?
              <button
              type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isConfirming }
                onClick={confirmSkipRequest}
              >
                {!isConfirming && <span className='indicator-label'>  {intl.formatMessage({ id: 'confirm' })}</span>}
                {isConfirming && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              : ""}



{(isCanEditting || (store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).status == "confirm") && !isCreateOpe?
              <button
              type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isCanceling }
                onClick={cancelRequest}
              >
                {!isCanceling && <span className='indicator-label'>  {intl.formatMessage({ id: 'cancel' })}</span>}
                {isCanceling && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              
              : ""}




          
        </div>
      </div>
      <div className='card-body py-5'>
     
          <div className='row'>
            <div className='col-sm-12'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-6'>
                    <label htmlFor='' className='required form-label'>
                      {intl.formatMessage({ id: 'reasonToSkip' })}
                    </label>
                    <input
                     disabled={!isCanEditting}
                      type='text' className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.reasonToSkip && formik.errors.reasonToSkip,
                      },
                      {
                        'is-valid': formik.touched.reasonToSkip && !formik.errors.reasonToSkip,
                      }
                    )}
                      {...formik.getFieldProps('reasonToSkip')}
                      placeholder={intl.formatMessage({ id: 'reasonToSkip' })}
                    />
                    {formik.touched.reasonToSkip && formik.errors.reasonToSkip && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.reasonToSkip}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12'>
              <div className='row'>
                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'employeeName' })}
                  </label>
                  <select
                   disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.employee && formik.errors.employee,
                      },
                      {
                        'is-valid': formik.touched.employee && !formik.errors.employee,
                      }
                    )}
                    {...formik.getFieldProps('employee')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}
                  >
                    <option> {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}</option>
                    {employees.map((item) => <option key={item['id']} value={item['id']} >{item['display_name']}</option>)}
                  </select>
                  {formik.touched.employee && formik.errors.employee && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.employee}</span>
                    </div>
                  )}
                </div>
                <div className='col-sm-6'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='mb-6'>
                        <label htmlFor='' className='form-label required'>
                          {intl.formatMessage({ id: 'date' })}
                        </label>
                        <input
                         disabled={!isCanEditting}
                        type='date' className={clsx(
                          'form-control form-control-lg form-control-solid',
                          {
                            'is-invalid': formik.touched.date && formik.errors.date,
                          },
                          {
                            'is-valid': formik.touched.date && !formik.errors.date,
                          }
                        )}
                          {...formik.getFieldProps('date')}

                        />
                        {formik.touched.date && formik.errors.date && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.date}</span>
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                </div>

                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'company' })}
                  </label>
                  <select
                   disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.company && formik.errors.company,
                      },
                      {
                        'is-valid': formik.touched.company && !formik.errors.company,
                      }
                    )}
                    {...formik.getFieldProps('company')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}</option>
                    {companies.map((item) => <option key={item['id']} value={item['id']}  >{item['display_name']}</option>)}
                  </select>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.company}</span>
                    </div>
                  )}
                </div>

                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'loan' })}
                  </label>
                  <select
                   disabled={!isCanEditting}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.loan && formik.errors.loan,
                      },
                      {
                        'is-valid': formik.touched.loan && !formik.errors.loan,
                      }
                    )}
                    {...formik.getFieldProps('loan')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'loan' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'loan' })
                    })}</option>
                    {loans.map((item) => <option key={item['id']} value={item['id']} >{item['display_name']}</option>)}

                  </select>
                  {formik.touched.loan && formik.errors.loan && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.loan}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>



          {isCreateOpe ? "" : <div className='row'>
            <div className='col-sm-12'>

              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link fw-bolder active' data-bs-toggle='tab' href='#kt_tab_pane_7'>
                    {intl.formatMessage({ id: 'extraInformation' })}
                  </a>
                </li>
              </ul>
              <div className='tab-content mb-6' id='myTabContent'>
                <div className='tab-pane fade show active' id='kt_tab_pane_7' role='tabpanel'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='row'>
                        <div className='col-sm-12 my-5'>
                          <h6>
                            {intl.formatMessage({ id: 'createdBy' })}:
                            <span className='text-muted fw-lighter ms-5'>   {((store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).createdBy??[])[1]??"-"}</span>
                          </h6>
                        </div>
                        <div className='col-sm-12'>
                          <h6>
                            {intl.formatMessage({ id: 'lastUpdatedBy' })}:
                            <span className='text-muted fw-lighter ms-5'>   {((store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).lastUpdatedBy??[])[1]??"-"}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='row'>
                        <div className='col-sm-12 my-5'>
                          <h6>
                            {intl.formatMessage({ id: 'createdOn' })}:
                            <span className='text-muted fw-lighter ms-5'>   {(store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).createdOn}</span>

                          </h6>
                        </div>
                        <div className='col-sm-12'>
                          <h6>
                            {intl.formatMessage({ id: 'lastUpdatedOn' })} :
                            <span className='text-muted fw-lighter ms-5'>   {(store.skippedRequestsReducer.selectedSkippedRequest??new SkipRequestModel()).lastUpdatedOn}</span>

                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          }

      </div>
    </div>
    </form>


  )
}
