
const ResultStatus = {
  ok: 200,
  notOk: 500, 
  somethingWrong: 501,
  unauthorized: 401,
  noTokenProvided: 403,
  pressureOnServer: 503,

};

export {
  ResultStatus,
}
