import { number } from "yup";
import CustodiesTypes from "../types/CustodiesTypes";
import CustodyModel from "../../models/CustodyModel";


interface CustodiesReducerInterface {
    selectedCustody: CustodyModel | undefined,
    custodies: CustodyModel[],
    custodiesCount: number,
    pageNumber: number

}

export const CUSTODIES_LIMITED = 10;

const initValue: CustodiesReducerInterface = {
    selectedCustody: undefined,
    custodies: [],
    custodiesCount: 0,
    pageNumber: 0,

}

export const CustodiesReducer = (state = initValue, action: any) => {
    console.log(action.type + " -> " + JSON.stringify(action.data));

    switch (action.type) {
        case CustodiesTypes.setCustodies:
            return { ...state, custodies: action.data.custodies, pageNumber: action.data.pageNumber };
        case CustodiesTypes.selectCustody:
            return { ...state, selectedCustody: action.data };
       
        case CustodiesTypes.setCustodiesCount:
            return { ...state, custodiesCount: action.data };
        default:
            return state;
    }
}