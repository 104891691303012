import React from "react";
import { LoansTable } from "../../_metronic/partials/widgets";
import { VacationsTable } from "../../_metronic/partials/widgets/tables/VacationsTable";
import { useIntl } from "react-intl";

export function AccessDenied() {
  const intl = useIntl()
  return (
    <>
      <h1>{intl.formatMessage({ id: 'accessDenied' })}</h1>
    </>
  )
}