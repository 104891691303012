import React from "react";
import { LoansTable } from "../../_metronic/partials/widgets";
import { useAppSelector } from "../../redux/Hook/hook";
import { AccessDenied } from "./AccessDenied";
                  
export function LoanPage() {


  const store = useAppSelector((state) => state);
  if(!store.configsReducer.loansAuth){
    return <AccessDenied/>
  }


    return (
        <>
   

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <LoansTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}


  </>
    )
}