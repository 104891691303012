/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { Cookies } from 'react-cookie'
import baseUrl from '../../../../Apis/apis'
import { ResultStatus } from '../../../../utils/AppConsts'
import CurrentUserTypes from '../../../../redux/types/CurrentUserTypes'
import { ConfigsActions } from '../../../../redux/actions/ConfigsActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'

export function AsideMenuMain() {
  const intl = useIntl()

  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(ConfigsActions.getAuthApps(intl));
  }, []);


  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'dashboard' })}
        fontIcon='bi-app-indicator'
      />

      {store.configsReducer.loansAuth ? <AsideMenuItemWithSub
        to='/loan-page'
        title={intl.formatMessage({ id: 'loans' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/loan-page' title={intl.formatMessage({ id: 'employeeLoans' })} hasBullet={true} />
        <AsideMenuItem to='/skip-installment' title={intl.formatMessage({ id: 'skippedRequests' })} hasBullet={true} />


      </AsideMenuItemWithSub> : ""}

      {store.configsReducer.salariesAuth ? <AsideMenuItemWithSub
        to='/employee-payslips'
        title={intl.formatMessage({ id: 'payslips' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/employee-payslips' title={intl.formatMessage({ id: 'employeePayslips' })} hasBullet={true} />
      </AsideMenuItemWithSub> : ""}


      {store.configsReducer.vacationsAuth ? <AsideMenuItemWithSub
        to='/vacations-page'
        title={intl.formatMessage({ id: 'vacations' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/vacations-page' title={intl.formatMessage({ id: 'vacations' })} hasBullet={true} />
      </AsideMenuItemWithSub> : ""}

      {store.configsReducer.custodiesAuth ? <AsideMenuItemWithSub
        to='/custodies-page'
        title={intl.formatMessage({ id: 'custodies' })}
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem to='/custodies-page' title={intl.formatMessage({ id: 'custodies' })} hasBullet={true} />
      </AsideMenuItemWithSub> : ""}


      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

    </>
  )
}
