import { CUSTODIES_LIMITED } from '../reducers/CustodiesReducer';
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl'
import { ResultStatus } from '../../utils/AppConsts';
import baseUrl from '../../Apis/apis';
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../types/CurrentUserTypes';
import CustodyModel from '../../models/CustodyModel';
import CustodiesTypes from '../types/CustodiesTypes';
import fileDownload from 'js-file-download'
import { log } from 'console';


const getCustodies = (
    intl: IntlShape, pageNumber: number) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/custody.custody/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["employee", "department", "date", "state", "deliv_date"],
                            "domain": [
                                ["employee", "=", Number(employeeId)]
                            ]
                            ,
                            "limit": CUSTODIES_LIMITED,
                            "offset": pageNumber * CUSTODIES_LIMITED
                        }
                    }
                }
                , {

                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        "X-Openerp-Session-Id": sessionId,
                    },
                });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data'] ?? {})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    console.log("custody Data is " + res.data);

                    const list = result['result'] ?? [];
                    var custodies: CustodyModel[] = [];
                    list.map((item: any) => {
                        const custody = CustodyModel.fromJson({
                            id: item.id,
                            requestData: item.date,
                            deliverDate: item.employee_id == false ? undefined : item.deliv_date,
                            status: item.state,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            department: item.department_id == false ? undefined : item.department_id,
                        });
                        custodies.push(custody);
                    });
                    dispatch({
                        type: CustodiesTypes.setCustodies, data: {
                            custodies: custodies,
                            pageNumber
                        }
                    });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}




const getCustodyById = (
    custodyId: number,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/custody.custody/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["employee", "department", "date", "state", "deliv_date"],
                            "domain": [["id", "=", Number(custodyId)]],
                            // "domain": [["id", "=", ]],
                            // "domain": [["id", "=", custodyId]],
                        }
                    }
                }
                , {

                    headers: {
                        'Accept': '*/*',
                        'Content-Type': 'application/json',
                        "X-Openerp-Session-Id": sessionId,
                    },
                });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data'] ?? {})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    console.log("custody Data is " + res.data);

                    const list = result['result'] ?? [];
                    if (list.length != 0) {
                        const item = list[0];
                        const custody = CustodyModel.fromJson({
                            id: item.id,
                            requestData: item.date,
                            deliverDate: item.employee_id == false ? undefined : item.deliv_date,
                            status: item.state,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            department: item.department_id == false ? undefined : item.department_id,
                        });

                        dispatch({
                            type: CustodiesTypes.selectCustody, data: custody
                        });
                    }

                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}




const getCustodiesCount = (
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/custody.custody/search_count",
                {
                    "params": {
                        "args": {
                            "args": [["employee", "=", Number(employeeId)]]
                            
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data'] ?? {})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const count = result['result'] ?? 0;
                    dispatch({ type: CustodiesTypes.setCustodiesCount, data: count });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}


const printCustody = (
    custodyId: any,
    intl: IntlShape,) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const cookies = new Cookies();
            const serverUrl = cookies.get('domain');
            var sessionId = cookies.get('session_id');

            const res = await baseUrl.post(serverUrl + '/apiV2/custody.custody/action_print_custody', {
                "params": {
                    "id": custodyId
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const data = result['result'];
                    if (data) {
                        //   window.open(serverUrl + data['url'], "_blank", "noreferrer");
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = serverUrl + data['url'];
                        a.download = serverUrl + data['url'];
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}


export const CustodiesActions = {
    getCustodies,
    getCustodyById,
    getCustodiesCount,
    printCustody
};