import axios from 'axios';
import LoansTypes from '../types/LoansTypes'
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl'
import UserModel from '../../models/UserModel';
import { ResultStatus } from '../../utils/AppConsts';
import LoanModel from '../../models/LoanModel';
import baseUrl from '../../Apis/apis';
import { LOANS_LIMITED } from '../reducers/LoansReducer';
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../types/CurrentUserTypes';


const create = (
    loanName: string,
    description: string,
    employeeId: string,
    companyId: string,
    amount: string,
    requestDate: string,
    eMIBasedOn: string,
    paymentStartDate: string,
    paymentDuratuin: string,
    deduction: string,
    navigator: NavigateFunction, intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/create", {
                "params": {
                    "args": {
                        "values": { "state": "draft", "name": loanName, "employee_id": employeeId, "company_id": companyId, "loan_type": "salary", "loan_amount": amount, "emi_based_on": eMIBasedOn, "request_date": requestDate, "start_date": paymentStartDate, "duration": paymentDuratuin, "description": description,   "deduction_amount": deduction, "message_follower_ids": [], "message_ids": [] }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    navigator(-1);
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const update = (
    loanId: string,
    loanName: string,
    description: string,
    employeeId: string,
    companyId: string,
    amount: string,
    requestDate: string,
    eMIBasedOn: string,
    paymentStartDate: string,
    paymentDuratuin: string,
    deduction: string,
   intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/write", {
                "params": {
                    "id": Number(loanId),
                    "args": {
                        "values": { "state": "draft", "name": loanName, "employee_id": employeeId, "company_id": companyId, "loan_type": "salary", "loan_amount": amount, "emi_based_on": eMIBasedOn, "request_date": requestDate, "start_date": paymentStartDate, "duration": paymentDuratuin, "description": description,   "deduction_amount": deduction, "message_follower_ids": [], "message_ids": [] }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });
            console.log("hi all" + JSON.stringify(res.data));


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getLoanById(loanId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                   
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}



const cancelLoan = (
    loanId: string,
    navigator: NavigateFunction,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/set_to_cancel", {
                "params": {
                    "id": Number(loanId)

                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        navigator(-1);
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}

const deleteLoan = (
    loanId: string,
    navigator: NavigateFunction,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/unlink", {
                "params": {
                    "id": Number(loanId)

                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        navigator(-1);
                    }else{
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const confirmLoan = (
    loanId: string,
   intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/confirm_loan", {
                "params": {
                    "id": Number(loanId)

                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getLoanById(loanId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                   
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const getLoans = (
    intl: IntlShape, pageNumber: number) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["name", "employee_id", "loan_amount", "request_date", "due_date", "state"],
                            "domain": [
                                ["employee_id", "=", Number(employeeId??"-1")]
                            ],
                            "limit": LOANS_LIMITED,
                            "offset": pageNumber * LOANS_LIMITED
                        },
                       
                    }
                }, {

                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    console.log("loans Data is " + result['result']);

                    const list = result['result'] ?? [];
                    var loans: LoanModel[] = [];
                    list.map((item: any) => {
                        const loan = LoanModel.fromJson({
                            id: item.id,
                            loanName: item.name,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            amount: item.loan_amount,
                            requestDate: item.request_date,
                            paymentEndDate: item.due_date,
                            status: item.state
                        });
                        loans.push(loan);
                    });
                    dispatch({
                        type: LoansTypes.setLoans, data: {
                            loans,
                            pageNumber
                        }
                    });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}


const getLoanById = (
    loanId: string,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["create_uid","create_date","write_uid","write_date","name", "department_id", "start_date", "deduction_amount", "duration", "amount_to_pay", "amount_paid", "emi_based_on", "account_move_id", "description", "company_id", "employee_id", "loan_amount", "request_date", "due_date",
                                "state"],
                            "domain": [["id", "=", loanId]],
                        }

                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {

                    const list = result['result'] ?? [];

                    if (list.length != 0) {
                        const item = list[0];
                        const loan = LoanModel.fromJson({
                            id: item.id,
                            loanName: item.name,
                            description: item.description,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            department: item.department_id == false ? undefined : item.department_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            amount: item.loan_amount,
                            eMIBasedOn: item.emi_based_on,
                            requestDate: item.request_date,
                            paymentStartDate: item.start_date,
                            paymentEndDate: item.due_date,
                            status: item.state,
                            amountPaid: item.amount_paid,
                            amountToPay: item.amount_to_pay,
                            installmentDeducation: item.deduction_amount,
                            paymentDuratuin: item.duration,
                            deductionAmount: item.deduction_amount,
                            createdBy : item.create_uid,
                            lastUpdatedBy : item.write_uid,
                            createdOn : item.create_date,
                            lastUpdatedOn : item.write_date,

                        });

                        dispatch({ type: LoansTypes.selectLoan, data: loan });
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}



const getLoansCount = (
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            console.log("employeeId ======> " + employeeId);
            console.log("my loan id " + serverUrl);
            console.log("my loan id " + sessionId);
            
            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/search_count",
                {
                    "params": {
                        "args": {
                            "args": [
                                ["employee_id","=", Number(employeeId)]
                            ],

                            
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const count = result['result'] ?? 0;
                    dispatch({ type: LoansTypes.setLoansCount, data: count });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }



        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}



const calclationLoan = (
    loanId: string,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.loan/make_calculation", {
                "params": {
                    "id": Number(loanId)
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        alert(intl.formatMessage({ id: 'done' }));

                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


export const LoansActions = {
    create,
    update,
    getLoans,
    getLoanById,
    getLoansCount,
    cancelLoan,
    deleteLoan,
    confirmLoan,
    calclationLoan
};