/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
// import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import { ConfigsActions } from '../../../../redux/actions/ConfigsActions'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import { CurrerUserActions } from '../../../../redux/actions/CurrentUserActions'
import CurrentUserTypes from '../../../../redux/types/CurrentUserTypes'
import baseUrl from '../../../../Apis/apis'
import { ResultStatus } from '../../../../utils/AppConsts'
import { domain } from 'process'
import { Cookies } from 'react-cookie'


/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [isHaveAppsAuth, setIsHaveAppsAuth] = useState(false)
  const [isGettingAppAuth, setIsGettingAppAuth] = useState(false)

  var navigator = useNavigate();
  const intl = useIntl()

  const relode_page = (): void => {
    // eslint-disable-next-line no-restricted-globals
    setTimeout(() =>{
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },4500)
  };

  const getAppsAuth = async () => {
    setIsGettingAppAuth(true);
    setIsHaveAppsAuth(false);

    try {
      const cookies = new Cookies();
      const res = await baseUrl.post("https://odoosmtapp.smt.sa/get_apps/",
        {
          "params": { "domain": formik.values.domain }
        }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
      }
    );




      if (res.status == ResultStatus.ok) {
        const result = res.data;
        var errorMessage: string = (result['error'] ?? {})['message'];

        if (errorMessage) {
          alert(errorMessage);
          if (errorMessage.includes("Odoo Session Expired")) {
            dispatch({ type: CurrentUserTypes.signout })
          }
        } else {
          var appsResult: [] = result['result'] ?? [];
          if (appsResult.length != 0) {
            setIsHaveAppsAuth(true);
          
          dispatch(ConfigsActions.getDatabases(intl));
          }
        }
      } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
      }


    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }));
    }

    setIsGettingAppAuth(false);
  }



  const loginSchema = Yup.object().shape({
    domain: Yup.string()
      .url(intl.formatMessage({ id: 'invalid' }) + " " + intl.formatMessage({ id: 'domain' }))
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'domain' })),
    username: Yup.string()
      .min(3, intl.formatMessage({ id: 'minimumMessage' }, { num: 3 }))
      .max(50, intl.formatMessage({ id: 'minimumMessage' }, { num: 50 }))
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'username' })),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: 'minimumMessage' }, { num: 3 }))
      .max(50, intl.formatMessage({ id: 'minimumMessage' }, { num: 50 }))
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'password' })),
    db: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'database' })
      })),
  })

  const initialValues = {
    domain: '',
    username: '',
    password: '',
    db: '',
  }
  // const initialValues = {
  //   domain: 'https://hr.test.smt.sa',
  //   username: 'mosab@hr.com',
  //   password: '123',
  //   db: 'hr',
  // }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      
      setLoading(true)
      await dispatch(CurrerUserActions.login(values.domain,values.username, values.password, values.db, navigator, intl))
      setLoading(false)
    },
  })



  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch()


  useEffect(() => {
    
    dispatch(ConfigsActions.getDatabases(intl));
  }, [])

  useEffect(() => {
   var databases =  store.configsReducer.databases as string[];
   if(databases.length == 1){
    formik.values.db = databases[0];
   }
  }, [store.configsReducer.databases])

  useEffect(() => {
    const cookies = new Cookies();
    cookies.set('domain',formik.values.domain);
    getAppsAuth();
  }, [formik.values.domain]);


  return (
    <form
      className='form w-100'
      onSubmit={(e)=>{
        e.preventDefault();
        formik.handleSubmit(e);
      }}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: 'signInTo' })} HR</h1>
      </div>
      {/* begin::Heading */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            {intl.formatMessage({ id: 'takeAccountMessage' })}<br />
            {intl.formatMessage({ id: 'domain' })} {" -> "} <strong>https://hr.test.smt.sa</strong><br />
            {intl.formatMessage({ id: 'username' })} {" -> "} <strong>mosab@hr.com</strong><br />
            {intl.formatMessage({ id: 'password' })}{" -> "} <strong>123</strong>
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'domain' })}</label>
        <input
          placeholder={intl.formatMessage({ id: 'domain' })}
          {...formik.getFieldProps('domain')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.domain && formik.errors.domain },
            {
              'is-valid': formik.touched.domain && !formik.errors.domain,
            }
          )}
          type='email'
          name='domain'
          autoComplete='off'
        />
        {formik.touched.domain && formik.errors.domain && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.domain}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {
        isHaveAppsAuth ? (<>
          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'username' })}</label>
            <input
              placeholder={intl.formatMessage({ id: 'username' })}
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.username && formik.errors.username },
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              type='email'
              name='username'
              autoComplete='off'
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.username}</span>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'password' })}</label>
                {/* end::Label */}
              </div>
            </div>
            <input
              type='password'
              placeholder={intl.formatMessage({ id: 'password' })}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            {/* begin::Link */}

            {/* end::Link */}
          </div>

          {/* end::Form group */}



         {(store.configsReducer.databases as[]).length > 1?( 
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>
                {/* begin::Label */}
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: 'database' })}</label>
                {/* end::Label */}
              </div>
            </div>
            <select
              className={clsx(
                'form-select form-select-solid mb-10',
                {
                  'is-invalid': formik.touched.db && formik.errors.db,
                },
                {
                  'is-valid': formik.touched.db && !formik.errors.db,
                }
              )}
              {...formik.getFieldProps('db')}

              aria-label={intl.formatMessage({ id: 'select?' }, {
                option: intl.formatMessage({ id: 'database' })
              })}
            // disabled
            >
              <option> {intl.formatMessage({ id: 'select?' }, {
                option: intl.formatMessage({ id: 'database' })
              })}</option>
              {
                store.configsReducer.databases.map((db: string) => <option key={db} value={db}>{db}</option>)
              }
            </select>
            {formik.touched.db && formik.errors.db && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.db}</span>
                </div>
              </div>
            )}
          </div>
        ) : ""}






          {/* begin::Action */}
       {formik.errors.db}
       {formik.errors.domain}
       {formik.errors.username}
      {formik.errors.password  }
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-lg btn-primary w-100 my-5'
              disabled={formik.isSubmitting || !formik.isValid}
              onClick={relode_page}
            >
              {!loading && <span className='indicator-label'>  {intl.formatMessage({ id: 'login' })}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'pleaseWait' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}</>) : ""
      }
    </form>
  )
}
