import React from "react";
import { EmployeePayslipsTable } from "../../_metronic/partials/widgets/tables/EmployeePayslipsTable";
import { useAppSelector } from "../../redux/Hook/hook";
import { AccessDenied } from "./AccessDenied";
                  
export function EmployeePayslips() {
  const store = useAppSelector((state) => state);
  if(!store.configsReducer.salariesAuth){
    return <AccessDenied/>
  }


    return (
        <>
   

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <EmployeePayslipsTable className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}


  </>
    )
}