export interface LoanInterface {
    id?: number,
    status?: string,
    loanName?: string,
    description?: string,
    employee?: any[] | undefined,
    department?: any[] | undefined,
    company?: any[] | undefined,
    amount?: string,
    requestDate?: string,
    eMIBasedOn?: string,
    paymentStartDate?: string,
    installmentDeducation?: string,
    amountPaid?: string,
    paymentDuratuin?: string,
    deductionAmount?: string,
    amountToPay?: string,
    paymentEndDate?: string,
    createdBy?: any[] | undefined,
    lastUpdatedBy?: any[] | undefined,
    createdOn?: string,
    lastUpdatedOn?: string,
}

class LoanModel {
    public id?: number;
    public loanName?: string;
    public status?: string;
    public description?: string;
    public employee?: any[] | undefined;
    public department?: any[] | undefined;
    public company?: any[] | undefined;
    public amount?: string;
    public requestDate?: string;
    public eMIBasedOn?: string;
    public paymentStartDate?: string;
    public installmentDeducation?: string;
    public amountPaid?: string;
    public paymentDuratuin?: string;
    public deductionAmount?: string;
    public amountToPay?: string;
    public paymentEndDate?: string;
    public createdBy?: any[] | undefined;
    public lastUpdatedBy?: any[] | undefined;
    public createdOn?: string;
    public lastUpdatedOn?: string;



    public static fromJson(json: LoanInterface): LoanModel {
        const loan = new LoanModel();
        loan.id = json.id;
        loan.status = json.status;
        loan.loanName = json.loanName;
        loan.description = json.description;
        loan.employee = json.employee;
        loan.department = json.department;
        loan.company = json.company;
        loan.amount = json.amount;
        loan.requestDate = json.requestDate;
        loan.eMIBasedOn = json.eMIBasedOn;
        loan.paymentStartDate = json.paymentStartDate;
        loan.installmentDeducation = json.installmentDeducation;
        loan.amountPaid = json.amountPaid;
        loan.paymentDuratuin = json.paymentDuratuin;
        loan.deductionAmount = json.deductionAmount;
        loan.amountToPay = json.amountToPay;
        loan.paymentEndDate = json.paymentEndDate;
        loan.createdBy = json.createdBy;
        loan.lastUpdatedBy = json.lastUpdatedBy;
        loan.createdOn = json.createdOn;
        loan.lastUpdatedOn = json.lastUpdatedOn;
        return loan;
    }

    public get statusColor(): string {
        switch (this.status) {
            case 'draft':
                return 'badge badge-secondary';
            case 'confirm':
                return 'badge badge-success';
            case 'open':
                return 'badge badge-info';
            case 'refuse':
                return 'badge badge-danger';
            case 'approve':
                return 'badge badge-dark';
            case 'done':
                return 'badge badge-light';
                case 'cancel':
            return 'badge badge-danger';
            default:
                return 'badge badge-dark';
        }
    }

}


export default LoanModel;