import ReactDOM from 'react-dom'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/

// for english

// import './_metronic/assets/sass/style.scss'

// for arabic

import './_metronic/assets/sass/style.bundle.rtl.css'



import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { Provider } from 'react-redux'
import { store } from './redux/store/Store'



/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */




Chart.register(...registerables)

const queryClient = new QueryClient()




ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
          <AppRoutes />
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>,
  </Provider>,
  document.getElementById('root')
)
