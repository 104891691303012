 const _skippedRequestsTypes = "/skipped-requests/";
 const setSkippedRequests = _skippedRequestsTypes + "set-skipped-requests";
 const selectSkippedRequest = _skippedRequestsTypes + "select-skipped-request";
 const setSkippedRequestsCount = _skippedRequestsTypes + "set-skipped-requests-count";

 
 const SkippedRequestsTypes = {
  setSkippedRequests,
  selectSkippedRequest,
  setSkippedRequestsCount,
 }

 export default SkippedRequestsTypes;