import axios from 'axios';
import LoansTypes from '../types/LoansTypes'
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl'
import { ResultStatus } from '../../utils/AppConsts';
import LoanModel from '../../models/LoanModel';
import PayslipModel from '../../models/PayslipModel';
import PayslipsTypes from '../types/PayslipsTypes';
import baseUrl from '../../Apis/apis';
import { PAYSLIPS_LIMITED } from '../reducers/PayslipsReducer';
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../types/CurrentUserTypes';


const getPayslips = (
    intl: IntlShape, pageNumber: number) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.payslip/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["number", "employee_id", "payslip_run_id", "company_id", "state", "struct_id", "date_from", "date_to", "basic_wage", "net_wage"],
                            "domain": [
                                ["employee_id","=",Number(employeeId)]
                            ],
                            "limit": PAYSLIPS_LIMITED,
                            "offset": pageNumber * PAYSLIPS_LIMITED
                        },



                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const list = result['result'] ?? [];
                    var payslips: PayslipModel[] = [];
                    list.map((item: any) => {
                        const payslip = PayslipModel.fromJson({
                            id: item.id,
                            reference: item.number,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            batch: item.payslip_run_id == false ? undefined : item.payslip_run_id,
                            company: item.company == false ? undefined : item.company,
                            basicWage: item.basic_wage,
                            netWage: item.net_wage,
                            status: item.state
                        });
                        payslips.push(payslip);
                    });
                    dispatch({
                        type: PayslipsTypes.setPayslips, data: {
                            payslips,
                            pageNumber
                        }
                    });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }


        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}


const getPayslipById = (
    payslipId: string,
    intl: IntlShape) => {
    console.log("getPayslipById OK");

    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.payslip/search_read",
                {
                    "params": {
                        "args": {
                            "fields": ["number", "employee_id", "payslip_run_id", "company_id", "department_id", "contract_id", "state", "struct_id", "date_from", "date_to", "basic_wage", "net_wage"],
                            "domain": [
                                ["id", "=", payslipId],
                                ["employee_id","=",Number(employeeId)]
                            ],
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const list = result['result'] ?? [];

                    if (list.length != 0) {
                        const item = list[0];
                        const payslip = PayslipModel.fromJson({
                            id: item.id,
                            dateFrom: item.date_from,
                            dateTo: item.date_to,
                            reference: item.number,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            department: item.department_id == false ? undefined : item.department_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            contract: item.contract_id == false ? undefined : item.contract_id,
                            batch: item.payslip_run_id == false ? undefined : item.payslip_run_id,
                            structure: item.struct_id == false ? undefined : item.struct_id,
                            basicWage: item.basic_wage,
                            netWage: item.net_wage,
                            status: item.state,
                        });

                        dispatch({ type: PayslipsTypes.selectPayslip, data: payslip });

                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }


        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}


const getPayslipsCount = (
    intl: IntlShape,) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl + "/apiV2/hr.payslip/search_count",
                {
                    "params": {
                        "args": {
                            "args": [
                                ["employee_id","=",Number(employeeId)]
                            ],
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const count = result['result'] ?? 0;
                    dispatch({ type: PayslipsTypes.setPayslipsCount, data: count });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }



        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}



const printPayroll = (
    payrollId: any,
    intl: IntlShape,) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const cookies = new Cookies();
            const serverUrl = cookies.get('domain');
            var sessionId = cookies.get('session_id');

            const res = await baseUrl.post(serverUrl + '/apiV2/hr.payslip/action_print_payslip', {
                "params": {
                    "id": payrollId
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = (result['error'] ?? {})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const data = result['result'];
                    if (data) {
                        // window.open(serverUrl + data['url'], "_blank", "noreferrer");

                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = serverUrl + data['url'];
                        a.download = serverUrl + data['url'];
                        a.click();
                        document.body.removeChild(a);

                    } else {
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}





export const PayslipsActions = {
    getPayslips,
    getPayslipById,
    getPayslipsCount,
    printPayroll
};