import ConfigsTypes from "../types/ConfigsTypes";
import { UserInterface } from '../../models/UserModel'


interface ConfigsReducerInterface {
  databases: string[],
  vacationsAuth: boolean,
  salariesAuth: boolean,
  custodiesAuth: boolean,
  loansAuth: boolean,
}


const initValue: ConfigsReducerInterface = {
  databases: [],
  vacationsAuth: false,
  salariesAuth: false,
  custodiesAuth: false,
  loansAuth: false,
}

export const ConfigsReducer = (state = initValue, action: any) => {
  console.log(action.type + " -> " + JSON.stringify(action.data));

  switch (action.type) {
    case ConfigsTypes.setDatabases:
      return { ...state, databases: action.data };

    case ConfigsTypes.setAuth:
      var vacationsAuth = false;
      var salariesAuth = false;
      var custodiesAuth = false;
      var loansAuth = false;
      (action.data as []).map((auth) => {
        switch (auth) {
          case "hr":
            vacationsAuth = true;
            break;
          case "leave":
            salariesAuth = true;
            break;
          case "Loan":
            loansAuth = true;
            break;
          case "payroll":
            salariesAuth = true;
            break;
          case "custody":
            custodiesAuth = true;
            break;
        }
      });
      return { ...state, vacationsAuth, loansAuth, salariesAuth, custodiesAuth };
    default:
      return state;
  }
}