import SkipRequestModel from "../../models/SkipRequestModel";
import SkippedRequestsTypes from "../types/SkippedRequestsTypes";



interface SkippedRequestsReducerInterface {
   skippedRequests: SkipRequestModel[],
   selectedSkippedRequest: SkipRequestModel | undefined,
   skippedRequestsCount: number,
   pageNumber:number

}

export const SKIPPED_REQUESTS_LIMITED = 10;


const initValue:SkippedRequestsReducerInterface = {
   skippedRequests:[],
   selectedSkippedRequest:undefined,
     skippedRequestsCount: 0,
    pageNumber:0,

}

export const SkippedRequestsReducer = (state = initValue, action:any) => {
   console.log(action.type + " -> " + JSON.stringify(action.data));

   switch (action.type) {
      case SkippedRequestsTypes.setSkippedRequests:
         return { ...state,skippedRequests:action.data.skippedRequests ,pageNumber:action.data.pageNumber};
      case SkippedRequestsTypes.selectSkippedRequest:
         return { ...state ,selectedSkippedRequest:action.data};
         case SkippedRequestsTypes.setSkippedRequestsCount:
            return { ...state,skippedRequestsCount:action.data };
      default:
         return state;
   }
}