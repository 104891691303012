export interface UserInterface {
    id?: number;
    name?: string;
    birthday?: string;
    gender?: string;
    identificationId?: string;
    idExpireDate?: string;
    department?: [];
    job?: [];
    country?: [];
    company?: [];
    homeAddress?: [];
    emailAddress?: string;
    phone?: string;
    visaNo?: string;
    visaExpire?: string;
    ibanNo?: string;
    employeeType?: string;
    employeeNo?: string;
    photoUrl?: string;
    photo64?: string;
    sessionId?: string;
    marital?: string;
    infants?:string;
    religion?: string;
    licenseNumber?: string;
    licenseType?: string;
    licenseExpireDate?: string;
    canEditProfile: boolean;

}

class UserModel {
    public id?: number;
    public name?: string;
    public birthday?: string;
    public gender?: string;
    public identificationId?: string;
    public idExpireDate?: string;
    public department?: [];
    public job?: [];
    public country?: [];
    public company?: [];
    public homeAddress?: [];
    public emailAddress?: string;
    public phone?: string;
    public visaNo?: string;
    public visaExpire?: string;
    public ibanNo?: string;
    public employeeType?: string;
    public employeeNo?: string;
    public photoUrl?: string;
    public photo64?: string;
    public sessionId?: string;
    public marital?: string;
    public infants?:string;
    public religion?: string;
    public licenseNumber?: string;
    public licenseType?: string;
    public licenseExpireDate?: string;
    public canEditProfile : boolean = false;

    public static fromJson(json: any): UserModel {
        const user = new UserModel();
        user.id = json['id'] == false ? null : json['id'];
        user.name = json['name'] == false ? null : json['name'];
        user.emailAddress = json['private_email'] == false ? null : json['private_email'];
        user.marital = json['marital'] == false ? null : json['marital'];
        user.infants = json['infants'] == false ? null : json['infants'];
        user.religion = json['religion'] == false ? null : json['religion'];
        user.licenseNumber = json['license_number'] == false ? null : json['license_number'];
        user.licenseType = json['license_type'] == false ? null : json['license_type'];
        user.licenseExpireDate = json['license_expire_date'] == false ? null : json['license_expire_date'];
        user.birthday = json['birthday'] == false ? null : json['birthday'];
        user.gender = json['gender'] == false ? null : json['gender'];
        user.photo64 = json['image_1920'] == false ? null : json['image_1920'];
        user.phone =
            json['phone'] == false ? null : json['phone'];
        user.identificationId = json['identification_id'] == false
            ? null
            : json['identification_id'];
        user.idExpireDate = json['id_expire_date'] == false
            ? null
            : json['id_expire_date'];
        user.job = json['job_id'] == false ? null : json['job_id'];
        user.homeAddress = json['address_home_id'] == false ? null : json['address_home_id'];
        user.company = json['company_id'] == false ? null : json['company_id'];
        user.country = json['country_id'] == false ? null : json['country_id'];
        user.department = json['department_id'] == false ? null : json['department_id'];
        user.visaNo = json['visa_no'] == false ? null : json['visa_no'];
        user.visaExpire =
            json['visa_expire'] == false ? null : json['visa_expire'];
        user.ibanNo = json['iban_number'] == false ? null : json['iban_number'];
        user.employeeType =
            json['employee_type'] == false ? null : json['employee_type'];
        user.employeeNo = json['employee_number'] == false
            ? null
            : json['employee_number'];
            user.canEditProfile = json['can_edit_his_profile'] == null ? false : json['can_edit_his_profile'];
        return user;
    }
}


export default UserModel;