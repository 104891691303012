export interface VacationInterface {
     id?: number;
     vacationType?: string;
     ticketType?: string;
     leaveBack?: string;
     vacationBaseOn?: string;
     status?: string;
     payrollType?: string;
     description?: string;
     employee?: any[] | undefined;
     department?: any[] | undefined;
     company?: any[] | undefined;
     duration?:string;
     dateTo?: string;
     dateFrom?: string;
     createdBy?: any[] | undefined,
    lastUpdatedBy?: any[] | undefined,
    createdOn?: string,
    lastUpdatedOn?: string
}

class VacationModel {
    public id?: number;
    public vacationType?: string;
    public ticketType?: string;
    public payrollType?: string;
    public leaveBack?: string;
    public vacationBaseOn?: string;
    public status?: string;
    public description?: string;
    public employee?: any[] | undefined;
    public department?: any[] | undefined;
    public company?: any[] | undefined;
    public duration?:string;
    public dateTo?: string;
    public dateFrom?: string;
    public createdBy?: any[] | undefined;
    public lastUpdatedBy?: any[] | undefined;
    public createdOn?: string;
    public lastUpdatedOn?: string;
    



    public static fromJson(json: VacationInterface): VacationModel {
        const vacation = new VacationModel();
        vacation.id = json.id;
        vacation.status = json.status;
        vacation.payrollType = json.payrollType;
        vacation.vacationType = json.vacationType;
        vacation.vacationBaseOn = json.vacationBaseOn;
        vacation.ticketType = json.ticketType;
        vacation.description = json.description;
        vacation.employee = json.employee;
        vacation.company = json.company;
        vacation.department = json.department;
        vacation.duration = json.duration;
        vacation.leaveBack = json.leaveBack;
        vacation.status = json.status;
        vacation.dateFrom = json.dateFrom;
        vacation.dateTo = json.dateTo;
        vacation.createdBy = json.createdBy;
        vacation.lastUpdatedBy = json.lastUpdatedBy;
        vacation.createdOn = json.createdOn;
        vacation.lastUpdatedOn = json.lastUpdatedOn;
        return vacation;
    }

    public get statusColor(): string {
        switch (this.status) {
            case 'draft':
                return 'badge badge-secondary';
            case 'confirm':
                return 'badge badge-success';
            case 'open':
                return 'badge badge-info';
            case 'refuse':
                return 'badge badge-danger';
            case 'approve':
                return 'badge badge-dark';
            case 'done':
                return 'badge badge-light';
                case 'cancel':
            return 'badge badge-danger';
            default:
                return 'badge badge-dark';
        }
    }

}


export default VacationModel;