import { applyMiddleware, combineReducers, createStore} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { CurrentUserReducer } from '../reducers/CurrentUserReducer';
import { SkippedRequestsReducer } from '../reducers/SkippedRequestsReducer';
import { LoansReducer } from '../reducers/LoansReducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { PayslipsReducer } from '../reducers/PayslipsReducer';
import { ConfigsReducer } from '../reducers/ConfigsReducer';
import { VacationsReducer } from '../reducers/VacationsReducer';
import { CustodiesReducer } from '../reducers/CustodiesReducer';


const reducer = combineReducers({
        custodiesReducer:CustodiesReducer,
        currentUserReducer:CurrentUserReducer,
        loansReducer:LoansReducer,
        skippedRequestsReducer:SkippedRequestsReducer,
        payslipsReducer:PayslipsReducer,
        configsReducer:ConfigsReducer,
        vacationsReducer:VacationsReducer,
})


export const store = createStore(reducer,composeWithDevTools(applyMiddleware(thunk)),);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

