 const _loansTypes = "/loans/";

 const setLoans = _loansTypes + "set-loans";
 const selectLoan = _loansTypes + "select-loan";
 const addLoan = _loansTypes + "add-loan";

 const setLoansCount = _loansTypes + "set-loans-count";

 const LoansTypes = {
   setLoans,
   selectLoan,
   addLoan,
   setLoansCount,
 }

 export default LoansTypes;