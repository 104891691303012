 const _payslipsTypes = "/payslips/";
 const setPayslips = _payslipsTypes + "set-payslips";
 const selectPayslip = _payslipsTypes + "select-payslip";
 const setPayslipsCount = _payslipsTypes + "set-payslips-count";

 const PayslipsTypes = {
    setPayslips,
    selectPayslip,
   setPayslipsCount
 }

 export default PayslipsTypes;