/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG, LogModel } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import LoanModel from '../../../../models/LoanModel'
import { LoansActions } from '../../../../redux/actions/LoansActions'
import ListPagenation from '../pagenation/ListPagenation'
import { LOANS_LIMITED } from '../../../../redux/reducers/LoansReducer'
import baseUrl from '../../../../Apis/apis'
import { ResultStatus } from '../../../../utils/AppConsts'
import { VacationsActions } from '../../../../redux/actions/VacationsActions'
import VacationModel from '../../../../models/VacationModel'
import { VACATIONS_LIMITED } from '../../../../redux/reducers/VacationsReducer'

type Props = {
  className: string
}

export const VacationsTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const store = useAppSelector((state) => state);


  const dispatch = useAppDispatch()

  const navigator = useNavigate();


  const pageNavigatorHandler = (data: any) => {
    dispatch(VacationsActions.getVacations(intl, data.selected));
  }


  useEffect(() => {
    dispatch(VacationsActions.getVacationsStatus(intl));
    dispatch(VacationsActions.getVacationsCount(intl));
    dispatch(VacationsActions.getVacations(intl, store.vacationsReducer.pageNumber));
  }, [])


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'vacations' })} {store.vacationsReducer.vacationsCount > 0 ? ' : ' + store.vacationsReducer.vacationsCount:' ' } </span>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'vacations' })} </span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a loan'
        >
          <Link
            to='/vacations-form/create'
            className='btn btn-light-primary'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({ id: 'createVacation' })}

          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>{intl.formatMessage({ id: 'vacationType' })} </th>
                <th>{intl.formatMessage({ id: 'description' })} </th>
                <th>{intl.formatMessage({ id: 'startDate' })}</th>
                <th>{intl.formatMessage({ id: 'endDate' })}</th>
                <th>{intl.formatMessage({ id: 'duration' })}</th>
                <th>{intl.formatMessage({ id: 'status' })}</th>
                <th className='text-end'>{intl.formatMessage({ id: 'operations' })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {
                store.vacationsReducer.vacations.map((vacation: VacationModel) => {
                  return (
                    <tr key={vacation.id}>
                      <td> {vacation.vacationType == undefined ? "" : vacation.vacationType[1]}</td>
                      <td>
                        <div className='d-flex align-items-center'>

                          <div className='d-flex justify-content-start flex-column'>
                              {vacation.description??"-"}
                          </div>
                        </div>
                      </td>
                      <td> {vacation.dateFrom ?? "-"}</td>
                      <td> {vacation.dateTo ?? "-"}</td>
                      <td> {vacation.duration ?? "-"}</td>
                      <td>
                        <span className={vacation.statusColor}>{(store.vacationsReducer.vacationsStatusAsMap as Map<any,any>).get(vacation.status??"-")??"-"}</span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' to={'/vacations-form/' + vacation.id}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </Link>
                        </div>
                      </td>
                    </tr>
                  );
                }

                )

              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ListPagenation pageCount={store.vacationsReducer.vacationsCount / VACATIONS_LIMITED} pageNavigatorHandler={pageNavigatorHandler} />


     

    </div>
  )
}


