import { number } from "yup";
import VacationsTypes from "../types/VacationsTypes";
import VacationModel from "../../models/VacationModel";


interface VacationsReducerInterface {
    selectedVacation: VacationModel | undefined,
    vacations: VacationModel[],
    vacationsStatusAsMap: Map<any,any>,
    vacationsStatusAsList: any[],
    vacationsCount: number,
    pageNumber: number

}

export const VACATIONS_LIMITED = 10;

const initValue: VacationsReducerInterface = {
    selectedVacation: undefined,
    vacationsStatusAsMap:new Map(),
    vacationsStatusAsList:[],
    vacations: [],
    vacationsCount: 0,
    pageNumber: 0,

}

export const VacationsReducer = (state = initValue, action: any) => {
    console.log(action.type + " -> " + JSON.stringify(action.data));

    switch (action.type) {
        case VacationsTypes.setVacations:
            return { ...state, vacations: action.data.vacations, pageNumber: action.data.pageNumber };
        case VacationsTypes.selectVacation:
            return { ...state, selectedVacation: action.data };
        case VacationsTypes.setVacationsCount:
            return { ...state, vacationsCount: action.data };
            case VacationsTypes.setStatus:
                return { ...state, vacationsStatusAsMap : action.data.map,vacationsStatusAsList:action.data.list };
        default:
            return state;
    }
}