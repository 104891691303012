import { number } from "yup";
import LoansTypes from "../types/LoansTypes";
import PayslipModel from "../../models/PayslipModel";
import PayslipsTypes from "../types/PayslipsTypes";


interface PayslipsReducerInterface {
    payslips: PayslipModel[],
    selectedPayslip: PayslipModel | undefined,
    payslipsCount:number,
    pageNumber:number

 }

 export const PAYSLIPS_LIMITED = 10;

const initValue:PayslipsReducerInterface  = {
    payslips:[],
    selectedPayslip: undefined,
    payslipsCount:0,
    pageNumber:0,

}

export const PayslipsReducer = (state = initValue, action: any) => {
    console.log(action.type + " -> " + JSON.stringify(action.data));


    switch (action.type) {
        case PayslipsTypes.setPayslips:
            return { ...state,payslips:action.data.payslips,pageNumber:action.data.pageNumber};
            case PayslipsTypes.selectPayslip:
                return { ...state,selectedPayslip:action.data};
                case PayslipsTypes.setPayslipsCount:
                    return { ...state,payslipsCount:action.data};
        default:
            return state;
    }
}