 const _vacations = "/vacations/";
 const setVacations = _vacations + "set-vacations";
 const setVacationsCount = _vacations + "set-vacations-count";
 const selectVacation = _vacations + "select-vacation";
 const setStatus = _vacations + "set-status";

 const VacationsTypes = {
  setVacations,
  setStatus,
  selectVacation,
  setVacationsCount,
 }

 export default VacationsTypes;