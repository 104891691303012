/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG, LogModel } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import LoanModel from '../../../../models/LoanModel'
import { LoansActions } from '../../../../redux/actions/LoansActions'
import ListPagenation from '../pagenation/ListPagenation'
import { LOANS_LIMITED } from '../../../../redux/reducers/LoansReducer'
import baseUrl from '../../../../Apis/apis'
import { ResultStatus } from '../../../../utils/AppConsts'

type Props = {
  className: string
}

export const LoansTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const store = useAppSelector((state) => state);


  const dispatch = useAppDispatch()

  const navigator = useNavigate();


  const pageNavigatorHandler = (data: any) => {
    dispatch(LoansActions.getLoans(intl, data.selected));
  }



  useEffect(() => {
    dispatch(LoansActions.getLoansCount(intl))
    dispatch(LoansActions.getLoans(intl, store.loansReducer.pageNumber))
    console.log(store.loansReducer.loansCount+"===")
  }, [])


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'loans' })} {store.loansReducer.loansCount > 0 ?' : '+ store.loansReducer.loansCount : ''} </span>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'loans' })} {store.loansReducer.loans.length > 0?(' : '+store.loansReducer.loans.length):(' ')} </span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a loan'
        >
          <Link
            to='/loan-form/create'
            className='btn btn-light-primary'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({ id: 'createLoan' })}

          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>{intl.formatMessage({ id: 'loanName' })} </th>
                <th>{intl.formatMessage({ id: 'employeeName' })} </th>
                <th>{intl.formatMessage({ id: 'amount' })}</th>
                <th>{intl.formatMessage({ id: 'requestDate' })}</th>
                <th>{intl.formatMessage({ id: 'paymentEndDate' })}</th>
                <th>{intl.formatMessage({ id: 'status' })}</th>
                <th className='text-end'>{intl.formatMessage({ id: 'operations' })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {
                store.loansReducer.loans.map((loan: LoanModel) => {
                  return (
                    <tr key={loan.id}>
                      <td>{loan.loanName ?? "-"}</td>
                      <td>
                        <div className='d-flex align-items-center'>

                          <div className='d-flex justify-content-start flex-column'>
                              {loan.employee == undefined ? "" : loan.employee[1]}
                          </div>
                        </div>
                      </td>
                      <td> {loan.amount ?? "-"}</td>
                      <td> {loan.requestDate ?? "-"}</td>
                      <td> {loan.paymentEndDate ?? "-"}</td>
                      <td>
                        <span className={loan.statusColor}>{loan.status}</span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>

                        <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' to={'/loan-form/' + loan.id}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </Link>

                        </div>
                      </td>
                    </tr>
                  );
                }

                )

              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ListPagenation pageCount={store.loansReducer.loansCount / LOANS_LIMITED} pageNavigatorHandler={pageNavigatorHandler} />
    </div>
  )
}


