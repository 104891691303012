import React from 'react'
import { useIntl } from 'react-intl';
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';

const ListPagenation = ({pageNavigatorHandler,pageCount}) => {
  const intl = useIntl()
  const cookies = new Cookies();
  var sessionId = cookies.get('session_id');
  var serverUrl = cookies.get('domain');
  var employeeId = cookies.get('employeeId');

    return (
        <div className='mt-5 mb-5'>
        <ReactPaginate
        breakLabel={"* . * . " + intl.formatMessage({ id: 'more' }) + " . * . *"}
        nextLabel={intl.formatMessage({ id: 'next' }) + " >"}
        onPageChange={(data)=>{
            console.log(data.selected);
            pageNavigatorHandler(data);
            console.log(employeeId);
        }}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={"< " + intl.formatMessage({ id: 'previous' })}
        renderOnZeroPageCount={null}
        containerClassName='pagination'
        pageClassName='page-item'
        pageLinkClassName='page-link'
        previousClassName='page-item'
        nextClassName='page-item'
        previousLinkClassName='page-link'
        nextLinkClassName='page-link'
        breakClassName='page-item'
        breakLinkClassName='page-link'
        activeClassName='active'
      />
      </div>
      );
}

export default ListPagenation