import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import baseUrl from '../../Apis/apis'
import { ResultStatus } from '../../utils/AppConsts'
import { useAppDispatch, useAppSelector } from '../../redux/Hook/hook'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../../redux/types/CurrentUserTypes'
import VacationsTypes from '../../redux/types/VacationsTypes'
import { VacationsActions } from '../../redux/actions/VacationsActions'
import moment from 'moment'
import VacationModel from '../../models/VacationModel'
import { format } from 'date-fns';
import { AccessDenied } from './AccessDenied'

export function VacationForm() {
  const store = useAppSelector((state) => state);
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isGettingSelected, setIsGettingSelected] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [payrollsTypes, setPayrollsTypes] = useState([]);
  const [vacationsTypes, setVacationsTypes] = useState([]);
  const [leavesBacks, setLeavesBacks] = useState([]);
  const [ticketsTypes, setTicketsTypes] = useState([]);
  const [vacationsBasesOn, setVacationsBasesOn] = useState([]);
  const [status, setStatus] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isSaudi, setIsSaudi] = useState([]);

  const cookies = new Cookies();
  var sessionId = cookies.get('session_id');


  const intl = useIntl()
  const dispatch = useAppDispatch();


  const [supportDocument, setSupportDocument] = useState(false);
  const [annualLeave, setAnnualLeave] = useState(false);
  const [onlyOneTime, setOnlyOneTime] = useState(false);
  const [needGuarantor, setNeedGuarantor] = useState(false);
  const [cashVacationAllowance, setCashVacationAllowance] = useState(false);
  const [sickLeave, setSickLeave] = useState(false);
  const [flyingTickets, setFlyingTickets] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false)




  const initialValues = {
    employeeName: "",
    department: "",
    company: "",
    vacationType: "",
    description: "",
    vacationBaseOn: "",
    payrollType: "",
    ticketType: "",
    leaveBack: "",
    dateFrom: "",
    dateTo: "",
    duration: ""
  };



  const navigator = useNavigate();
  const isCreateOpe = window.location.pathname.includes('/create');
  const [isCanEditting, setIsCanEditting] = useState(isCreateOpe);
  let vacationId = "-1";
  if (!isCreateOpe) {
    var index = window.location.pathname.lastIndexOf('/');
    vacationId = window.location.pathname.substring(index + 1);
  }







  var vacationSchema = Yup.object().shape({
    employeeName: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'employeeName' })
      })),
    vacationType: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'vacationType' })
      })),
    vacationBaseOn: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'vacationBaseOn' })
      })),
    ticketType: flyingTickets ? Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'ticketType' })
      })) : Yup.string(),

    description: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'description' })),
    payrollType: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'payrollType' })
      })),
    leaveBack: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'leaveBack' })
      })), dateFrom: Yup.string()
        .required(intl.formatMessage({ id: 'select?' }, {
          option: intl.formatMessage({ id: 'dateFrom' })
        })), dateTo: Yup.string()
          .required(intl.formatMessage({ id: 'select?' }, {
            option: intl.formatMessage({ id: 'dateTo' })
          })),
  });



  const [isGettingDefaultValues, setIsGettingDefaultValues] = useState(false);
  const [isGettingLookup, setIsGettingLookup] = useState(false);

  var formik = useFormik({
    initialValues,
    validationSchema: vacationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsSubmiting(true)
      try {
        if (isCreateOpe) {
          await dispatch(VacationsActions.createVacation(values.employeeName, values.vacationType, values.vacationBaseOn, values.description, values.dateFrom, values.dateTo, values.leaveBack, values.ticketType, values.payrollType, navigator, intl));

        } else {
          await dispatch(VacationsActions.updateVacation(vacationId, values.employeeName, values.vacationType, values.vacationBaseOn, values.description, values.dateFrom, values.dateTo, values.leaveBack, values.ticketType, values.payrollType, navigator, intl));
        }
      } catch (error) {
        console.error(error)
      }
      setIsSubmiting(false)

    },
  })



  const getDefaultValues = async () => {
    setIsGettingDefaultValues(true);
    try {
      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');
      const res = await baseUrl.post(serverUrl + '/apiV2/hr.loan/default_get', {
        "params": {
          "args": {
            "fields_list": ["employee_id", "request_date", "company_id"]
          }
        }
      }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
        var errorMessage: string = ((result['error'] ?? {})['data'] ?? {})['message'];

        if (errorMessage) {
          alert(errorMessage);
          if (errorMessage.includes("Odoo Session Expired")) {
            dispatch({ type: CurrentUserTypes.signout })
          }
        } else {
          const data = result['result'];
          if (data) {
            console.log(data['employee_id']);
            console.log(data['company_id']);
            console.log(data['department_id']);
            formik.values.employeeName = data['employee_id'];
            formik.values.company = data['company_id'];
            formik.values.department = data['department_id'];
          } else {
            alert(intl.formatMessage({ id: 'tryAgain' }));
          }

        }
      } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
      }



    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingDefaultValues(false);
  }


  const getLookup = async () => {
    setIsGettingLookup(true);
    try {
      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');
      const res = await baseUrl.post(serverUrl + '/apiV2/lookup/hr.leave', {
        "params": {
          "lookups": [
            {
              "field_model": "leave_back",
              "args": {
                "fields": ["display_name"],
                "domain": [],
              }
            },
            {
              "field_model": "holiday_allocation_id",
              "args": {
                "fields": ["display_name"],
                "domain": [],
              }
            },
            {
              "field_model": "department_id",
              "args": {
                "fields": ["display_name"],
                "domain": [],
              }
            },
            {
              "field_model": "company_id",
              "args": {
                "fields": ["display_name"],
                "domain": [],
              }
            },
            {
              "field_model": "employee_id",
              "args": {
                "fields": ["display_name"],
                "domain": [],
                // "limit": 20
              }
            },
            {
              "field_model": "vacation_type",
              "args": {
                "fields": [""],
                "domain": []
              }
            },
            {
              "field_model": "holiday_type",
              "args": {
                "fields": [""],
                "domain": []
              }
            },
            {
              "field_model": "state",
              "args": {
                "fields": [""],
                "domain": []
              }
            },
            {
              "field_model": "payroll_type",
              "args": {
                "fields": [""],
                "domain": []
              }
            },
            {
              "field_model": "employee_id",
              "args": {
                "fields": ["display_name", "is_saudi"],
                "domain": formik.values.employeeName == "" ? [] : [
                  ["id", "=", formik.values.employeeName]
                ]
              }
            },
            {
              "field_model": "holiday_status_id",
              "args": {
                "fields": ["display_name"],
                "domain": [],
              }
            }
          ]
        }
      }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
        var errorMessage: string = ((result['error'] ?? {})['data'] ?? {})['message'];


        if (errorMessage) {
          alert(errorMessage);
          if (errorMessage.includes("Odoo Session Expired")) {
            dispatch({ type: CurrentUserTypes.signout })
          }
        } else {
          const data = res.data['result'];
          if (data) {
            setStatus(((data['states'] ?? {})['selection'] ?? []));
            setLeavesBacks(((data['leave_back'] ?? {})['selection'] ?? []));
            setVacationsBasesOn(((data['vacation_type'] ?? {})['selection'] ?? []));
            setTicketsTypes(((data['ticket_type'] ?? {})['selection'] ?? []));
            setPayrollsTypes(((data['payroll_type'] ?? {})['selection'] ?? []));
            setVacationsTypes((data['holiday_status_id'] ?? []));

            setEmployees(data['employee_id'] ?? []);
            setCompanies(data['company_id'] ?? []);
            setDepartments(data['department_id'] ?? []);
            setIsSaudi(((data['employee_id'] ?? []) as []).length == 0
              ? false
              : (((data['employee_id'] ?? [])[0]["is_saudi"] ?? true)));
              
          } else {
            alert(intl.formatMessage({ id: 'tryAgain' }));
          }
        }
      } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
      }



    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingLookup(false);
  }


  const calculate = () => {
    try {
      var startDate = moment(formik.values.dateFrom);
      var timeEnd = moment(formik.values.dateTo);
      var diff = timeEnd.diff(startDate);
      var diffDuration = moment.duration(diff);
      formik.values.duration = Math.abs(diffDuration.days()).toString();

    } catch (ex) {
      formik.values.duration = "0";

    }
  }


  const confirmVacation = async () => {
    setIsConfirming(true);
    await dispatch(VacationsActions.confirmVacation(vacationId, navigator, intl));
    setIsConfirming(false);

  }

  const cancelVacation = async () => {
    setIsCanceling(true);
    await dispatch(VacationsActions.cancelVacation(vacationId, navigator, intl));
    setIsCanceling(false);
  }

  useEffect(() => {
    var selecteds = vacationsTypes.filter(type => {
      return type['id'] == formik.values.vacationType;
    });


    setSupportDocument(false);
      setAnnualLeave( false);
      setOnlyOneTime( false);
      setNeedGuarantor(false);
      setFlyingTickets(false);
      setCashVacationAllowance(false);
      setSickLeave(false);


    if (selecteds.length != 0) {
      var selected = selecteds[0];
      setSupportDocument(selected['support_document'] ?? false);
      setAnnualLeave(selected['annual_leave'] ?? false);
      setOnlyOneTime(selected['only_one_time'] ?? false);
      setNeedGuarantor(selected['need_guarantor'] ?? false);
      setFlyingTickets(selected['flying_tickets'] ?? false);
      setCashVacationAllowance(selected['cash_vacation_allowance'] ?? false);
      setSickLeave(selected['sick_leave'] ?? false);
      if (!flyingTickets) {
        formik.values.ticketType = "";
      }
    }

  }, [formik.values.vacationType]);


  const initPage = async () => {
    if (isCreateOpe) {
      await getDefaultValues();
    } else {
      await dispatch(VacationsActions.getVacationById(vacationId, intl));
    }
    await dispatch(VacationsActions.getVacationsStatus(intl));
    await getLookup();
  }


  useEffect(() => {
    initPage();
  }, [])



  // Set form values when  selected loan geted
  useEffect(() => {
    setIsGettingSelected(true);

    if (!isCreateOpe) {
      const selectedVacation: VacationModel | undefined = store.vacationsReducer.selectedVacation;
      console.log("selectedVacation is " + JSON.stringify(store.vacationsReducer.selectedVacation));
      if (selectedVacation) {
        setIsCanEditting(selectedVacation.status == "draft");

        formik.values.employeeName = selectedVacation.employee == null ? "null" : selectedVacation.employee![0];
        formik.values.company = selectedVacation.company == null ? "null" : selectedVacation.company![0];
        formik.values.department = selectedVacation.department == null ? "null" : selectedVacation.department![0];
        formik.values.vacationType = selectedVacation.vacationType == null ? "null" : selectedVacation.vacationType![0];

        formik.values.payrollType = selectedVacation.payrollType ?? "";
        formik.values.vacationBaseOn = selectedVacation.vacationBaseOn ?? "";
        formik.values.description = selectedVacation.description ?? "";
        formik.values.ticketType = selectedVacation.ticketType ?? "";
        formik.values.leaveBack = selectedVacation.leaveBack ?? "";

        try {
          var date = new Date(selectedVacation.dateTo ?? "");
          formik.values.dateTo = format(date, "yyyy-MM-dd");
          console.log(formik.values.dateTo);

        } catch (ex) {
          console.log("date to error " + ex);

        }


        try {
          var date = new Date(selectedVacation.dateFrom ?? "");
          formik.values.dateFrom = format(date, "yyyy-MM-dd");
        } catch (ex) {
          console.log("date from error " + ex);

        }


        formik.values.duration = selectedVacation.duration ?? "";
      }
    }
    setIsGettingSelected(false);
  }, [store.vacationsReducer.selectedVacation])


  useEffect(() => {
    calculate();
  }, [formik.values.dateFrom, formik.values.dateTo,])


  if(!store.configsReducer.vacationsAuth){
    return <AccessDenied/>
  }


  return (
    <form
    onSubmit={(e)=>{
      e.preventDefault();
      formik.handleSubmit(e);
    }}
      noValidate
      id='kt_loan_form'>
 <div className="card mb-5">
        <div className='col-sm-12 status'>
          {isCreateOpe ? "" : <h3 className='indicator-label  mb-0 d-flex justify-content-between align-items-center'>
            <div>
              {store.vacationsReducer.vacationsStatusAsList.map((item: any) => <span className={(store.vacationsReducer.selectedVacation ?? new VacationModel()).status == item[0] ? 'text-primary mx-5 active' : 'text-black-50 mx-5'}>   {item[1]} </span>)}
            </div>
          </h3>}
        </div>
      </div>


      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>{intl.formatMessage({ id: isCreateOpe ? 'createVacation' : 'showVacation' })}</h3>
          <div className='card-toolbar'>
            {isCreateOpe || isCanEditting ?
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isSubmiting && <span className='indicator-label'>  {intl.formatMessage({ id: isCreateOpe ? 'confirm' : 'update' })}</span>}
                {isSubmiting && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              : ""}

            {isCanEditting && !isCreateOpe ?
              <button
                type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isConfirming}
                onClick={confirmVacation}
              >
                {!isConfirming && <span className='indicator-label'>  {intl.formatMessage({ id: 'confirm' })}</span>}
                {isConfirming && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              : ""}
          </div>
        </div>




        <div className='card-body py-5'>


          <div className='row'>
            <div className='col-sm-12'>
              <h5 className='mb-4 mt-4 text-primary'>{intl.formatMessage({ id: 'empolyeeDetails' })}</h5>
              <div className='row'>
                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'employeeName' })}
                  </label>
                  <select disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.employeeName && formik.errors.employeeName,
                      },
                      {
                        'is-valid': formik.touched.employeeName && !formik.errors.employeeName,
                      }
                    )}
                    {...formik.getFieldProps('employeeName')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}
                  // disabled
                  >
                    <option> {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}</option>
                    {employees.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.employeeName && formik.errors.employeeName && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.employeeName}</span>
                    </div>
                  )}
                </div>

                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'department' })}
                  </label>
                  <select
                    disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.department && formik.errors.department,
                      },
                      {
                        'is-valid': formik.touched.department && !formik.errors.department,
                      }
                    )}
                    {...formik.getFieldProps('department')}
                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'department' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'department' })
                    })}</option>
                    {departments.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.department && formik.errors.department && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.department}</span>
                    </div>
                  )}
                </div>

                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'company' })}
                  </label>
                  <select
                    disabled={true}

                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.company && formik.errors.company,
                      },
                      {
                        'is-valid': formik.touched.company && !formik.errors.company,
                      }
                    )}
                    {...formik.getFieldProps('company')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}</option>
                    {companies.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.company}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>



          <div className='row'>
            <div className='col-sm-12'>
              <h5 className='mb-4 mt-4 text-primary'>{intl.formatMessage({ id: 'vacationDetails' })}</h5>
              <div className='row'>
                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'vacationType' })}
                  </label>
                  <select
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.vacationType && formik.errors.vacationType,
                      },
                      {
                        'is-valid': formik.touched.vacationType && !formik.errors.vacationType,
                      }
                    )}
                    {...formik.getFieldProps('vacationType')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'vacationType' })
                    })}
                  >
                    <option > {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'vacationType' })
                    })}</option>
                    {vacationsTypes.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.vacationType && formik.errors.vacationType && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.vacationType}</span>
                    </div>
                  )}
                </div>
                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'vacationBaseOn' })}
                  </label>
                  <select
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.vacationBaseOn && formik.errors.vacationBaseOn,
                      },
                      {
                        'is-valid': formik.touched.vacationBaseOn && !formik.errors.vacationBaseOn,
                      }
                    )}
                    {...formik.getFieldProps('vacationBaseOn')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'vacationBaseOn' })
                    })}
                  >
                    <option > {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'vacationBaseOn' })
                    })}</option>
                    {vacationsBasesOn.map((item) => <option key={item[0]} value={item[0]}>{item[1]}</option>)}

                  </select>
                  {formik.touched.vacationBaseOn && formik.errors.vacationBaseOn && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.vacationBaseOn}</span>
                    </div>
                  )}
                </div>
                {
                  isSaudi ? "" : (
                    <div className='col-lg-6'>
                      <label htmlFor='' className='required form-label'>
                        {intl.formatMessage({ id: 'leaveBack' })}
                      </label>
                      <select
                        className={clsx(
                          'form-select form-select-solid mb-6',
                          {
                            'is-invalid': formik.touched.leaveBack && formik.errors.leaveBack,
                          },
                          {
                            'is-valid': formik.touched.leaveBack && !formik.errors.leaveBack,
                          }
                        )}
                        {...formik.getFieldProps('leaveBack')}

                        aria-label={intl.formatMessage({ id: 'select?' }, {
                          option: intl.formatMessage({ id: 'leaveBack' })
                        })}
                      // disabled
                      >
                        <option> {intl.formatMessage({ id: 'select?' }, {
                          option: intl.formatMessage({ id: 'leaveBack' })
                        })}</option>
                        {leavesBacks.map((item) => <option key={item[0]} value={item[0]}>{item[1]}</option>)}

                      </select>
                      {formik.touched.leaveBack && formik.errors.leaveBack && (
                        <div className='fv-plugins-message-container'>
                          <span role='alert'>{formik.errors.leaveBack}</span>
                        </div>
                      )}
                    </div>
                  )
                }


                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'payrollType' })}
                  </label>
                  <select
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.payrollType && formik.errors.payrollType,
                      },
                      {
                        'is-valid': formik.touched.payrollType && !formik.errors.payrollType,
                      }
                    )}
                    {...formik.getFieldProps('payrollType')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'payrollType' })
                    })}
                  // disabled
                  >
                    <option> {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'payrollType' })
                    })}</option>
                    {payrollsTypes.map((item) => <option key={item[0]} value={item[0]}>{item[1]}</option>)}

                  </select>
                  {formik.touched.payrollType && formik.errors.payrollType && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.payrollType}</span>
                    </div>
                  )}
                </div>



                {!flyingTickets ? "" : <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'ticketType' })}
                  </label>
                  <select
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.ticketType && formik.errors.ticketType,
                      },
                      {
                        'is-valid': formik.touched.ticketType && !formik.errors.ticketType,
                      }
                    )}
                    {...formik.getFieldProps('ticketType')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'ticketType' })
                    })}
                  // disabled
                  >
                    <option> {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'ticketType' })
                    })}</option>
                    {ticketsTypes.map((item) => <option key={item[0]} value={item[0]}>{item[1]}</option>)}

                  </select>
                  {formik.touched.ticketType && formik.errors.ticketType && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.ticketType}</span>
                    </div>
                  )}
                </div>}
                <div className='col-lg-6'>
                  <div className='mb-6'>
                    <label htmlFor='' className='required form-label'>
                      {intl.formatMessage({ id: 'description' })}
                    </label>
                    <input type='text'
                      disabled={!isCanEditting}
                      placeholder={intl.formatMessage({ id: 'description' })}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      {...formik.getFieldProps('description')} />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>



          <div className='col-sm-12'>
            <h5 className='mb-4 mt-4 text-primary'>{intl.formatMessage({ id: 'duration' })}</h5>
            <div className='row'>
              <div className='col-lg-6'>
                <label htmlFor='' className='required form-label'>
                  {intl.formatMessage({ id: 'dateFrom' })}
                </label>
                <input
                  // disabled={!isCanEditting}
                  type='date'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.dateFrom && formik.errors.dateFrom,
                    },
                    {
                      'is-valid': formik.touched.dateFrom && !formik.errors.dateFrom,
                    }
                  )}
                  {...formik.getFieldProps('dateFrom')} />
                {formik.touched.dateFrom && formik.errors.dateFrom && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.dateFrom}</span>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label htmlFor='' className='required form-label'>
                  {intl.formatMessage({ id: 'dateTo' })}
                </label>
                <input
                  // disabled={!isCanEditting}
                  type='date'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.dateTo && formik.errors.dateTo,
                    },
                    {
                      'is-valid': formik.touched.dateTo && !formik.errors.dateTo,
                    }
                  )}
                  {...formik.getFieldProps('dateTo')} />
                {formik.touched.dateTo && formik.errors.dateTo && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.dateTo}</span>
                  </div>
                )}
              </div>
              <div className='col-lg-6'>
                <label htmlFor='' className='required form-label'>
                  {intl.formatMessage({ id: 'duration' })}
                </label>
                <input
                  disabled={true}
                  type='text'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.duration && formik.errors.duration,
                    },
                    {
                      'is-valid': formik.touched.duration && !formik.errors.duration,
                    }
                  )}
                  {...formik.getFieldProps('duration')} />
                {formik.touched.duration && formik.errors.duration && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.duration}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  )
}
