/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { KTSVG, LogModel } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import LoanModel from '../../../../models/LoanModel'
import { LoansActions } from '../../../../redux/actions/LoansActions'
import ListPagenation from '../pagenation/ListPagenation'
import { LOANS_LIMITED } from '../../../../redux/reducers/LoansReducer'
import baseUrl from '../../../../Apis/apis'
import { ResultStatus } from '../../../../utils/AppConsts'
import { CustodiesActions } from '../../../../redux/actions/CustodiesActions'
import VacationModel from '../../../../models/VacationModel'
import { CUSTODIES_LIMITED } from '../../../../redux/reducers/CustodiesReducer'
import CustodyModel from '../../../../models/CustodyModel'
import { format } from 'date-fns';
import { Button } from 'react-bootstrap'

type Props = {
  className: string
}

export const CustodiesTable: React.FC<Props> = ({ className }) => {
  const intl = useIntl()

  const store = useAppSelector((state) => state);
 var custodyId:any = -1;

  const dispatch = useAppDispatch()

  const navigator = useNavigate();


  const pageNavigatorHandler = (data: any) => {
    dispatch(CustodiesActions.getCustodies(intl, data.selected));
  }



  useEffect(() => {
    dispatch(CustodiesActions.getCustodiesCount(intl))
    dispatch(CustodiesActions.getCustodies(intl, store.custodiesReducer.pageNumber))
  }, [])


  const printCustody = (custodyId:any)=>{
    dispatch(CustodiesActions.printCustody(custodyId,intl))
  }



  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'custodies' })} {store.custodiesReducer.custodiesCount > 0 ? ' : ' + store.custodiesReducer.custodiesCount: ' '} </span>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'custodies' })} </span> */}
        </h3>
       
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>{intl.formatMessage({ id: 'requestDate' })} </th>
                <th>{intl.formatMessage({ id: 'deliverDate' })} </th>
                <th>{intl.formatMessage({ id: 'status' })}</th>
                <th className='text-end'>{intl.formatMessage({ id: 'operations' })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {
                store.custodiesReducer.custodies.map((custody: CustodyModel) => {
                  return (
                    <tr key={custody.id}>
                      
                      <td> {custody.requestData == null? "-" : (format(new Date(custody.requestData), 'yyyy/MM/dd'))}</td>
                      <td>
                        <div className='d-flex align-items-center'>

                          <div className='d-flex justify-content-start flex-column'>
                          {custody.deliverDate == null? "-" : (format(new Date(custody.deliverDate), 'yyyy/MM/dd'))}
                          </div>
                        </div>
                      </td>
                      <td>
                        <span className={custody.statusColor}>{custody.status}</span>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                        <Button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' onClick={
                          (e)=>printCustody(custody.id)
                        }>
                        <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
                        </Button>
                        </div>
                      </td>
                    </tr>
                  );
                }

                )

              }
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ListPagenation pageCount={store.custodiesReducer.custodiesCount / CUSTODIES_LIMITED} pageNavigatorHandler={pageNavigatorHandler} />



    </div>
  )
}


