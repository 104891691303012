/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../helpers'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../../redux/Hook/hook'
import SkipRequestModel from '../../../../models/SkipRequestModel'
import { SkippedRequestsActions } from '../../../../redux/actions/SkippedRequestsActions'
import ListPagenation from '../pagenation/ListPagenation'
import { SKIPPED_REQUESTS_LIMITED as Skipped_REQUESTS_LIMITED } from '../../../../redux/reducers/SkippedRequestsReducer'

type Props = {
  className: string
}

export const SkipInstallmentRequestTable: React.FC<Props> = ({ className }) => {

  const intl = useIntl()



  const store = useAppSelector((state) => state);
  const dispatch = useAppDispatch()


  const pageNavigatorHandler = (data:any)=>{
    dispatch(SkippedRequestsActions.getSkippedRequests(intl,data.selected));
  }

  useEffect(() => {
     dispatch(SkippedRequestsActions.getSkippedRequestsCount(intl));
    dispatch(SkippedRequestsActions.getSkippedRequests(intl,store.skippedRequestsReducer.pageNumber));
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          {/* <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'skippedRequests' })}: {store.skippedRequestsReducer.skippedRequestsCount}</span> */}
          <span className='card-label fw-bolder fs-3 mb-1'>{intl.formatMessage({ id: 'skippedRequests' })} {store.skippedRequestsReducer.skippedRequestsCount > 0 ?(' : '+store.skippedRequestsReducer.skippedRequestsCount):('') }</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <Link
            to='/skip-installment-form/create'
            className='btn btn-light-primary'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            {intl.formatMessage({ id: 'createSkip' })}
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bolder text-muted'>
                <th>{intl.formatMessage({ id: 'reasonToSkip' })}</th>
                <th>{intl.formatMessage({ id: 'employeeName' })}</th>
                <th>{intl.formatMessage({ id: 'loanName' })}</th>
                <th> {intl.formatMessage({ id: 'date' })}</th>
                <th> {intl.formatMessage({ id: 'status' })}</th>
                <th className='min-w-100px text-end'>{intl.formatMessage({ id: 'operations' })}</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {
               store.skippedRequestsReducer.skippedRequests.map((skipRequest: SkipRequestModel) => {
                  return (
                    <tr key={skipRequest.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark  text-hover-primary fs-6'>
                          {skipRequest.reasonToSkip??"-"}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                        {skipRequest.employee == undefined? "" : skipRequest.employee[1]}
                    </td>
                    <td>
                      {skipRequest.loan??"-"}
                    </td>
                    <td>{skipRequest.date??"-"}</td>
                    <td>
                        <span className={skipRequest.statusColor}>{skipRequest.status}</span>
                      </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                     
                      <Link className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' to={'/skip-installment-form/' + skipRequest.id}>
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                        </Link>

                        
                      </div>
                    </td>
                  </tr>
                  );
                })
              }
             
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      <ListPagenation pageCount={store.skippedRequestsReducer.skippedRequestsCount / Skipped_REQUESTS_LIMITED} pageNavigatorHandler={pageNavigatorHandler}/>
   
   

   
    </div>
  )
}

