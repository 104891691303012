import { domain } from 'process';
import axios from 'axios';
import VacationsTypes from '../types/VacationsTypes'
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { IntlShape } from 'react-intl'
import UserModel from '../../models/UserModel';
import { ResultStatus } from '../../utils/AppConsts';
import baseUrl from '../../Apis/apis';
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../types/CurrentUserTypes';
import VacationModel from '../../models/VacationModel';
import { VACATIONS_LIMITED } from '../reducers/VacationsReducer';


const createVacation = (
    employeeId: string,
    vacationType: string,
    vacationBaseOn: string,
    description: string,
    dateFrom: string,
    dateTo: string,
    leaveBack: string,
    ticketType: string,
    payrollType: string,
    navigator: NavigateFunction, intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            console.log(JSON.stringify({
                "params": {
                    "args": {
                        "vals_list": {
                            "payslip_state": "normal",
                            "state": "draft",
                            "payroll_id": false,
                            "holiday_type": "employee",
                            "employee_id": 22,
                            "holiday_status_id": Number(vacationType),
                            "vacation_type": vacationBaseOn,
                            "payroll_type": payrollType,
                            "date_from": dateFrom,
                            "date_to": dateTo,
                            "request_date_from": dateFrom,
                            "request_date_to": dateTo,
                            "request_date_from_period": "am",
                            "request_unit_half": false,
                            "request_unit_hours": false,
                            "request_unit_custom": false,
                            "name": description,
                            "leave_back": leaveBack,
                            // "supported_attachment_ids": [
                            //   [
                            //     6,
                            //     false,
                            //     []
                            //   ]
                            // ],
                            "guarantor_name": "",
                            "guarantor_phone_number": "",
                            "guarantor_id_number": "",
                            "ticket_type": ticketType,
                            "account_id": false,
                            "journal_id": false,
                            "message_follower_ids": [],
                            "activity_ids": [],
                            "message_ids": []
                        }
                    }
                }
            }));
            
            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/create", {
                "params": {
                    "args": {
                        "vals_list": {
                            "payslip_state": "normal",
                            "state": "draft",
                            "payroll_id": false,
                            "holiday_type": "employee",
                            "employee_id": 22,
                            "holiday_status_id": Number(vacationType),
                            "vacation_type": vacationBaseOn,
                            "payroll_type": payrollType,
                            "date_from": dateFrom,
                            "date_to": dateTo,
                            "request_date_from": dateFrom,
                            "request_date_to": dateTo,
                            "request_date_from_period": "am",
                            "request_unit_half": false,
                            "request_unit_hours": false,
                            "request_unit_custom": false,
                            "name": description,
                            "leave_back": leaveBack,
                            // "supported_attachment_ids": [
                            //   [
                            //     6,
                            //     false,
                            //     []
                            //   ]
                            // ],
                            "guarantor_name": "",
                            "guarantor_phone_number": "",
                            "guarantor_id_number": "",
                            "ticket_type": ticketType,
                            "account_id": false,
                            "journal_id": false,
                            "message_follower_ids": [],
                            "activity_ids": [],
                            "message_ids": []
                        }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                        var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    navigator(-1);
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const updateVacation = (
    vacationId: string,
    employeeId: string,
    vacationType: string,
    vacationBaseOn: string,
    description: string,
    dateFrom: string,
    dateTo: string,
    leaveBack: string,
    ticketType: string,
    payrollType: string,
    navigator: NavigateFunction, intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/write", {
                "params": {
                    "id": Number(vacationId),
                    "args": {
                        "vals": {
                            "payslip_state": "normal",
                            "state": "draft",
                            "payroll_id": false,
                            "holiday_type": "employee",
                            "employee_id": employeeId,
                            "holiday_status_id": Number(vacationType),
                            "vacation_type": vacationBaseOn,
                            "date_from": dateFrom,
                            "date_to": dateTo,
                            "request_date_from": dateFrom,
                            "request_date_to": dateTo,
                            "request_date_from_period": "am",
                            "request_unit_half": false,
                            "request_unit_hours": false,
                            "request_unit_custom": false,
                            "name": description,
                            "leave_back": leaveBack,
                            "payroll_type": payrollType,
                            // "supported_attachment_ids": [
                            //   [
                            //     6,
                            //     false,
                            //     []
                            //   ]
                            // ],
                            "guarantor_name": "",
                            "guarantor_phone_number": "",
                            "guarantor_id_number": "",
                            "ticket_type": ticketType,
                            "account_id": false,
                            "journal_id": false,
                            "message_follower_ids": [],
                            "activity_ids": [],
                            "message_ids": []
                        }
                    }
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });
            console.log("hi all" + JSON.stringify(res.data));


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getVacationById(vacationId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}



const cancelVacation = (
    vacationId: string,
    navigator: NavigateFunction,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {
            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/unlink", {
                "params": {
                    "id": Number(vacationId)
                    
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                        var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        navigator(-1);
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}

const deleteVacation = (
    vacationId: string,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/unlink", {
                "params": {
                    "id": Number(vacationId)
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                        var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if (result['result'] == true) {
                        // dispatch({ type: VacationsTypes.deleteVacation, data: vacationId });
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const confirmVacation = (
    vacationId: string,
    navigator: NavigateFunction, intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {

        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/action_confirm", {
                "params": {
                    "id": Number(vacationId)
                }
            }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                        var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    if(result['result'] === true){
                        await dispatch(getVacationById(vacationId,intl))
                        alert(intl.formatMessage({ id: 'done' }));
        
                    }else{
                        alert(intl.formatMessage({ id: 'tryAgain' }));
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ": -> " + ex);
        }
    }
}


const getVacations = (
    intl: IntlShape, pageNumber: number) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/search_read",
                {
                    "params": {
                        "args": {
                            "fields": [
                                "holiday_status_id",
                                "employee_id",
                                "name",
                                "duration_display",
                                "state",
                                "date_from",
                                "date_to",
                                "payroll_type",
                                "leave_back",
                                "ticket_type",
                                "vacation_type"
                            ],
                            "domain": [
                                ["employee_id","=", Number(employeeId)]
                            ],
                            "limit": VACATIONS_LIMITED,
                            "offset": pageNumber * VACATIONS_LIMITED
                        }
                    }
                }, {

                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    console.log("vacation Data is " + res.data);

                    const list = result['result'] ?? [];
                    var vacations: VacationModel[] = [];
                    list.map((item: any) => {
                        const vacation = VacationModel.fromJson({
                            id: item.id,
                            vacationType: item.holiday_status_id == false ? undefined : item.holiday_status_id,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            department: item.department_id == false ? undefined : item.department_id,
                            duration: item.duration_display,
                            description: item.name,
                            leaveBack: item.leave_back,
                            dateFrom: item.date_from,
                            dateTo: item.date_to,
                            status: item.state,
                            payrollType: item.payroll_type,
                            ticketType: item.ticket_type,
                            vacationBaseOn: item.vacation_type,
                            createdBy : item.create_uid,
                            lastUpdatedBy : item.write_uid,
                            createdOn : item.create_date,
                            lastUpdatedOn : item.write_date,

                        });
                        vacations.push(vacation);
                    });
                    dispatch({
                        type: VacationsTypes.setVacations, data: {
                            vacations: vacations,
                            pageNumber
                        }
                    });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}



const getVacationsStatus = (
    intl: IntlShape,) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/get_leave_state",
            {"params": {}}, {

                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });


            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    console.log("vacation Data is " + JSON.stringify(res.data));

                    const list = result['result'] ?? [];
                    var map = new Map();
                    list.map((item: any) => {
                        map.set(item[0],item[1]);
                    console.log("vacation Map is " + JSON.stringify(map));

                    });


                    dispatch({
                        type: VacationsTypes.setStatus, data: {list,map}
                    });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}


const getVacationById = (
    vacationId: string,
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');

            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/search_read",
                {
                    "params": {
                        "args": {
                            "fields": [
                                "holiday_status_id",
                                "employee_id",
                                "name",
                                "duration_display",
                                "state",
                                "date_from",
                                "date_to",
                                "payroll_type",
                                "leave_back",
                                "ticket_type",
                                "vacation_type"
                            ],
                            "domain": [
                                ["id", "=", Number(vacationId)]
                                // ["id", "=", ]
                            ],
                        }
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                        var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {

                    const list = result['result'] ?? [];

                    if (list.length != 0) {
                        const item = list[0];
                        const vacation = VacationModel.fromJson({
                            id: item.id,
                            vacationType: item.holiday_status_id == false ? undefined : item.holiday_status_id,
                            employee: item.employee_id == false ? undefined : item.employee_id,
                            company: item.company_id == false ? undefined : item.company_id,
                            department: item.department_id == false ? undefined : item.department_id,
                            duration: item.duration_display,
                            description: item.name,
                            leaveBack: item.leave_back,
                            dateFrom: item.date_from,
                            dateTo: item.date_to,
                            status: item.state,
                            payrollType: item.payroll_type,
                            ticketType: item.ticket_type,
                            vacationBaseOn: item.vacation_type,
                            createdBy : item.create_uid,
                            lastUpdatedBy : item.write_uid,
                            createdOn : item.create_date,
                            lastUpdatedOn : item.write_date,
                        });
                        console.log("my mq - " + item.start_date);

                        dispatch({ type: VacationsTypes.selectVacation, data: vacation });
                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }
        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }));
        }
    }
}



const getVacationsCount = (
    intl: IntlShape) => {
    return async (dispatch: Dispatch<any>) => {
        try {

            const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var employeeId = cookies.get('employeeId');
            // console.log("8", "args",[
            //     ["employee_id", "=", employeeId]
            // ]);
            const res = await baseUrl.post(serverUrl+"/apiV2/hr.leave/search_count",
                {
                    "params": {
                        "args": {
                            "args":[
                                ["employee_id", "=", Number(employeeId)]
                            ]
                        },
                    }
                }, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "X-Openerp-Session-Id": sessionId,
                },
            }
            );

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];


                if (errorMessage) {
                    alert(errorMessage);
                    if (errorMessage.includes("Odoo Session Expired")) {
                        dispatch({ type: CurrentUserTypes.signout })
                    }
                } else {
                    const count = result['result'] ?? 0;
                    dispatch({ type: VacationsTypes.setVacationsCount, data: count });
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }



        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
        }
    }
}


export const VacationsActions = {
    createVacation,
    updateVacation,
    getVacations,
    getVacationById,
    getVacationsCount,
    cancelVacation,
    deleteVacation,
    confirmVacation,
    getVacationsStatus
};