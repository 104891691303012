 const _custodies = "/custodies/";
 const setCustodies = _custodies + "set-custodies";
 const setCustodiesCount = _custodies + "set-custodies-count";
 const selectCustody = _custodies + "select-custody";

 const CustodiesTypes = {
  setCustodies,
  selectCustody,
  setCustodiesCount,
 }

 export default CustodiesTypes;