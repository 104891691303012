export interface SkipRequestInterface  {
    id?: number,
    status?: string,
    reasonToSkip?: string,
    employee?: any[] | undefined,
    date?: string,
    company?: any[] | undefined,
    loan?: any[] | undefined,
    createdBy?: any[] | undefined,
    lastUpdatedBy?: any[] | undefined,
    createdOn?: string,
    lastUpdatedOn?: string
}

class SkipRequestModel {
    public id?: number;
    public status?: string;
    public reasonToSkip?: string;
    public employee?: any[]| undefined;
    public date?: string;
    public company?: any[]| undefined;
    public loan?: any[]| undefined;
    public createdBy?: any[] | undefined;
    public lastUpdatedBy?: any[] | undefined;
    public createdOn?: string;
    public lastUpdatedOn?: string;
    
    
    public static fromJson(json: SkipRequestInterface): SkipRequestModel {
        const skipRequest = new SkipRequestModel();
        skipRequest.id = json.id;
        skipRequest.status = json.status;
        skipRequest.reasonToSkip = json.reasonToSkip;
        skipRequest.employee = json.employee;
        skipRequest.date = json.date;
        skipRequest.company = json.company;
        skipRequest.loan = json.loan;
        skipRequest.createdBy = json.createdBy;
        skipRequest.lastUpdatedBy = json.lastUpdatedBy;
        skipRequest.createdOn = json.createdOn;
        skipRequest.lastUpdatedOn = json.lastUpdatedOn;
        return skipRequest;
    }

   
    public get statusColor(): string {
        switch (this.status) {
            case 'draft':
                return 'badge badge-secondary';
            case 'confirm':
                return 'badge badge-success';
            case 'open':
                return 'badge badge-info';
            case 'refuse':
                return 'badge badge-danger';
            case 'approve':
                return 'badge badge-dark';
            case 'done':
                return 'badge badge-light';
                case 'cancel':
            return 'badge badge-danger';
            default:
                return 'badge badge-dark';
        }
    }
}


export default SkipRequestModel;