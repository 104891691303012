 const _currentUserTypes = "/current-user/";
 const login = _currentUserTypes + "set-profile";
 const signout = _currentUserTypes + "signout";
 const updateProfile = _currentUserTypes + "update-profile";

 const CurrentUserTypes = {
    login,
    signout,
    updateProfile
 }

 export default CurrentUserTypes;