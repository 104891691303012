import CurrentUserTypes from "../types/CurrentUserTypes";
import { UserInterface } from '../../models/UserModel'
import Cookies from 'universal-cookie';


interface CurrentUserReducerInterface {
    profile: UserInterface | undefined,
}


const initValue:CurrentUserReducerInterface = {
    profile: undefined,
}

export const CurrentUserReducer = (state = initValue, action: any) => {
    console.log(action.type + " -> " + JSON.stringify(action.data));

    switch (action.type) {
        case CurrentUserTypes.login:
            return { ...state, profile: action.data };
            case CurrentUserTypes.signout:
                const cookies = new Cookies();
                cookies.remove('session_id');
                cookies.remove('domain');
                return { ...state, profile: undefined };
        default:
            return state;
    }
}