import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import baseUrl from '../../Apis/apis'
import { ResultStatus } from '../../utils/AppConsts'
import { useAppDispatch, useAppSelector } from '../../redux/Hook/hook'
import { LoansActions } from '../../redux/actions/LoansActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoanModel from '../../models/LoanModel'
import Cookies from 'universal-cookie';
import CurrentUserTypes from '../../redux/types/CurrentUserTypes'
import { AccessDenied } from './AccessDenied'

export function LoanForm() {
  const store = useAppSelector((state) => state);
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isGettingSelected, setIsGettingSelected] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isCalculating, setIsCalculating] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [status, setStatus] = useState([]);


  const cookies = new Cookies();
  var sessionId = cookies.get('session_id');


  const intl = useIntl()
  const dispatch = useAppDispatch();

  const initialValues = {
    loanName: "",
    description: "",
    employeeName: "",
    department: "",
    company: "",
    amount: "",
    emiBased: "",
    amountPaid: "",
    amountToPay: "",
    requestDate: "",
    loanPaymentStartDate: "",
    paymentDuratuin: "",
    deductionAmount: "",
    loanPaymentEndDate: "",
    installmentDeducation: "",
  };



  const navigator = useNavigate();
  const isCreateOpe = window.location.pathname.includes('/create');
  const [isCanEditting, setIsCanEditting] = useState(isCreateOpe);
  let loanId = "-1";
  if (!isCreateOpe) {
    var index = window.location.pathname.lastIndexOf('/');
    loanId = window.location.pathname.substring(index + 1);
  }



  const loanSchema = Yup.object().shape({
    loanName: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'loanName' })),
    description: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'description' })),
    employeeName: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'employeeName' })
      })),
    amount: Yup.number()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'amount' })),
    emiBased: Yup.string()
      .required(intl.formatMessage({ id: 'select?' }, {
        option: intl.formatMessage({ id: 'emiBased' })
      })),
    requestDate: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'requestDate' })),
    loanPaymentStartDate: Yup.string()
      .required(intl.formatMessage({ id: 'enter' }) + " " + intl.formatMessage({ id: 'loanPaymentStartDate' })),
  });






  const [isGettingDefaultValues, setIsGettingDefaultValues] = useState(false);
  const [isGettingLookup, setIsGettingLookup] = useState(false);
  const [emiBasedOnSelection, setEmiBasedOnSelection] = useState([]);

  const formik = useFormik({
    initialValues,
    validationSchema: loanSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsSubmiting(true)
      try {
        if (isCreateOpe) {
          await dispatch(LoansActions.create(values.loanName, values.description, values.employeeName, values.company, values.amount, values.requestDate, values.emiBased, values.loanPaymentStartDate, values.paymentDuratuin, values.deductionAmount, navigator, intl));

        } else {
          await dispatch(LoansActions.update(loanId, values.loanName, values.description, values.employeeName, values.company, values.amount, values.requestDate, values.emiBased, values.loanPaymentStartDate, values.paymentDuratuin, values.deductionAmount, intl));
        }
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        // setStatus(intl.formatMessage({id: 'checkEmailOrPassword'}))
        // setSubmitting(false)
      }
      setIsSubmiting(false)

    },
  })



  const getDefaultValues = async () => {
    setIsGettingDefaultValues(true);
    try {
      console.log("databases api ");

      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');

      const res = await baseUrl.post(serverUrl + '/apiV2/hr.loan/default_get', {
        "params": {
          "args": {
            "fields_list": ["employee_id", "request_date", "company_id"]
          }
        }
      }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
        var errorMessage: string = (result['error'] ?? {})['message'];

        if (errorMessage) {
          alert(errorMessage);
          if (errorMessage.includes("Odoo Session Expired")) {
            dispatch({ type: CurrentUserTypes.signout })
          }
        } else {
          const data = result['result'];
          if (data) {

            formik.values.employeeName = data['employee_id'];
            formik.values.company = data['company_id'];
            formik.values.department = data['department_id'];
            console.log("my ids  " + formik.values.department);

          } else {
            alert(intl.formatMessage({ id: 'tryAgain' }));
          }

        }
      } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
      }



    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingDefaultValues(false);
  }


  const getLookup = async () => {
    setIsGettingLookup(true);
    try {
      const cookies = new Cookies();
      var serverUrl = cookies.get('domain');
      const res = await baseUrl.post(serverUrl + '/apiV2/lookup/hr.loan', {
        "params": {
          "lookups": [
            {
              "field_model": "employee_id",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },
            {
              "field_model": "emi_based_on",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },
            {
              "field_model": "company_id",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },
            {
              "field_model": "department_id",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },
            {
              "field_model": "refused_by",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },

            {
              "field_model": "approved_by",
              "args": {
                "fields": [
                  "display_name"
                ],
                "domain": [],
                "limit": 20
              }
            },
            {
              "field_model": "state",
              "args": {
                "fields": [
                  ""
                ],
                "domain": []
              }
            }
          ]
        }
      }, {
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
          "X-Openerp-Session-Id": sessionId,
        },
      });


      if (res.status == ResultStatus.ok) {
        const result = res.data;
        var errorMessage: string = (result['error'] ?? {})['message'];

        if (errorMessage) {
          alert(errorMessage);
          if (errorMessage.includes("Odoo Session Expired")) {
            dispatch({ type: CurrentUserTypes.signout })
          }
        } else {
          const data = res.data['result'];
          if (data) {
            setStatus((data['state'] ?? {})['selection'] ?? []);
            setEmiBasedOnSelection((data['emi_based_on'] ?? {})['selection'] ?? []);
            setEmployees(data['employee_id'] ?? []);
            setCompanies(data['company_id'] ?? []);
            setDepartments(data['department_id'] ?? []);
          } else {
            alert(intl.formatMessage({ id: 'tryAgain' }));
          }
        }
      } else {
        alert(intl.formatMessage({ id: 'tryAgain' }));
      }



    } catch (ex) {
      alert(intl.formatMessage({ id: 'checkInternet' }) + " :-> " + ex);
    }
    setIsGettingLookup(false);
  }



  const confirmLoan = async () => {
    setIsConfirming(true);
    await dispatch(LoansActions.confirmLoan(loanId, intl));
    setIsConfirming(false);

  }


  const loanCalclation = async () => {
    setIsCalculating(true);
    await dispatch(LoansActions.calclationLoan(loanId, intl));
    setIsCalculating(false);
  }

  const cancelLoan = async () => {
    setIsCanceling(true);
    await dispatch(LoansActions.cancelLoan(loanId, navigator, intl));
    setIsCanceling(false);
  }


  const initPage = async () => {
    if (isCreateOpe) {
      await getDefaultValues();
    } else {
      await dispatch(LoansActions.getLoanById(loanId, intl));
    }
    await getLookup();
  }

  useEffect(() => {
    initPage();

  }, [])

  // Set form values when  selected loan geted
  useEffect(() => {
    setIsGettingSelected(true);

    if (!isCreateOpe) {
      const selectedLoan: LoanModel | undefined = store.loansReducer.selectedLoan;
      console.log("selectLoan is " + JSON.stringify(store.loansReducer.selectedLoan));
      if (selectedLoan) {
        setIsCanEditting(selectedLoan.status == "draft");

        formik.values.loanName = selectedLoan.loanName ?? "";
        formik.values.description = selectedLoan.description ?? "";
        formik.values.employeeName = selectedLoan.employee == undefined ? "" : selectedLoan.employee[0];
        formik.values.department = selectedLoan.department == undefined ? "" : selectedLoan.department[0];
        formik.values.company = selectedLoan.company == undefined ? "" : selectedLoan.company[0];
        formik.values.amount = selectedLoan.amount ?? "";
        formik.values.requestDate = selectedLoan.requestDate ?? "";
        formik.values.emiBased = selectedLoan.eMIBasedOn ?? "";
        formik.values.loanPaymentStartDate = selectedLoan.paymentStartDate ?? "";
        formik.values.paymentDuratuin = selectedLoan.paymentDuratuin ?? "";
        formik.values.deductionAmount = selectedLoan.deductionAmount ?? "";
        formik.values.loanPaymentEndDate = selectedLoan.paymentEndDate ?? "";
        formik.values.installmentDeducation = selectedLoan.installmentDeducation ?? "";
        formik.values.amountPaid = selectedLoan.amountPaid ?? "";
        formik.values.amountToPay = selectedLoan.amountToPay ?? "";
      }
    }
    setIsGettingSelected(false);
  }, [store.loansReducer.selectedLoan])



  if(!store.configsReducer.loansAuth){
    return <AccessDenied/>
  }

  return (
    <form
    onSubmit={(e)=>{
      e.preventDefault();
      formik.handleSubmit(e);
    }}
      noValidate
      id='kt_loan_form'>
      <div className="card mb-5">
        <div className='col-sm-12 status'>
          {isCreateOpe ? "" : <h3 className='indicator-label  mb-0 d-flex justify-content-between align-items-center'>
            <div>
              {status.map((item) => <span className={(store.loansReducer.selectedLoan ?? new LoanModel()).status == item[0] ? 'text-primary mx-5 active' : 'text-black-50 mx-5'}>   {item[1]} </span>)}
            </div>
          </h3>}
        </div>
      </div>

      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>{intl.formatMessage({ id: isCreateOpe ? 'createLoan' : 'showLoan' })}</h3>
          <div className='card-toolbar'>
            {isCreateOpe || isCanEditting ?
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isSubmiting && <span className='indicator-label'>  {intl.formatMessage({ id: isCreateOpe ? 'confirm' : 'update' })}</span>}
                {isSubmiting && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              : ""}

            {isCanEditting && !isCreateOpe ?
              <button
                type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isConfirming}
                onClick={confirmLoan}
              >
                {!isConfirming && <span className='indicator-label'>  {intl.formatMessage({ id: 'confirm' })}</span>}
                {isConfirming && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              : ""}

            {isCanEditting && !isCreateOpe ?
              <button
                type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isCalculating}
                onClick={loanCalclation}
              >
                {!isCalculating && <span className='indicator-label'>  {intl.formatMessage({ id: 'calculation' })}</span>}
                {isCalculating && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              : ""}


            {(isCanEditting || (store.loansReducer.selectedLoan ?? new LoanModel()).status == "confirm") && !isCreateOpe ?
              <button
                type='button'
                className='btn btn-lg btn-primary ms-3  mb-5 mt-4'
                disabled={isCanceling}
                onClick={cancelLoan}
              >
                {!isCanceling && <span className='indicator-label'>  {intl.formatMessage({ id: 'cancel' })}</span>}
                {isCanceling && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: 'pleaseWait' })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>

              : ""}
          </div>

        </div>





        <div className='card-body py-5'>

          <div className='row'>
            <div className='col-sm-12'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-6'>
                    <label htmlFor='' className='required form-label'>
                      {intl.formatMessage({ id: 'loanName' })}
                    </label>
                    <input type='text'
                      disabled={!isCanEditting}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.loanName && formik.errors.loanName,
                        },
                        {
                          'is-valid': formik.touched.loanName && !formik.errors.loanName,
                        }
                      )}
                      {...formik.getFieldProps('loanName')}
                      placeholder={intl.formatMessage({ id: 'loanName' })}
                    />
                    {formik.touched.loanName && formik.errors.loanName && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.loanName}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-6'>
                    <label htmlFor='' className='required form-label'>
                      {intl.formatMessage({ id: 'description' })}
                    </label>
                    <input type='text'
                      disabled={!isCanEditting}
                      placeholder={intl.formatMessage({ id: 'description' })}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.description && formik.errors.description,
                        },
                        {
                          'is-valid': formik.touched.description && !formik.errors.description,
                        }
                      )}
                      {...formik.getFieldProps('description')} />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.description}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12'>
              <h5 className='mb-4 mt-4 text-primary'>{intl.formatMessage({ id: 'empolyeeDetails' })}</h5>
              <div className='row'>
                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'employeeName' })}
                  </label>
                  <select disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.employeeName && formik.errors.employeeName,
                      },
                      {
                        'is-valid': formik.touched.employeeName && !formik.errors.employeeName,
                      }
                    )}
                    {...formik.getFieldProps('employeeName')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}
                  // disabled
                  >
                    <option> {intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'employeeName' })
                    })}</option>
                    {employees.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.employeeName && formik.errors.employeeName && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.employeeName}</span>
                    </div>
                  )}
                </div>

                {isCreateOpe ? "" : <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'department' })}
                  </label>
                  <select
                    disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.department && formik.errors.department,
                      },
                      {
                        'is-valid': formik.touched.department && !formik.errors.department,
                      }
                    )}
                    {...formik.getFieldProps('department')}
                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'department' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'department' })
                    })}</option>
                    {departments.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.department && formik.errors.department && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.department}</span>
                    </div>
                  )}
                </div>
                }

                <div className='col-lg-6'>
                  <label htmlFor='' className='required form-label'>
                    {intl.formatMessage({ id: 'company' })}
                  </label>
                  <select
                    disabled={true}
                    className={clsx(
                      'form-select form-select-solid mb-6',
                      {
                        'is-invalid': formik.touched.company && formik.errors.company,
                      },
                      {
                        'is-valid': formik.touched.company && !formik.errors.company,
                      }
                    )}
                    {...formik.getFieldProps('company')}

                    aria-label={intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}
                  >
                    <option>{intl.formatMessage({ id: 'select?' }, {
                      option: intl.formatMessage({ id: 'company' })
                    })}</option>
                    {companies.map((item) => <option key={item['id']} value={item['id']}>{item['display_name']}</option>)}

                  </select>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.company}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-6'>
              <h5 className='mb-4 mt-4 text-primary'>  {intl.formatMessage({ id: 'loanDetails' })}</h5>
              <div className='mb-6'>
                <label htmlFor='' className='form-label required'>
                  {intl.formatMessage({ id: 'amount' })}
                </label>
                <input type='number'
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={!isCanEditting}
                  placeholder={intl.formatMessage({ id: 'amount' })}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.amount && formik.errors.amount,
                    },
                    {
                      'is-valid': formik.touched.amount && !formik.errors.amount,
                    }
                  )}
                  {...formik.getFieldProps('amount')} />
                {formik.touched.amount && formik.errors.amount && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.amount}</span>
                  </div>
                )}
              </div>
              <label htmlFor='' className='required form-label'>
                {intl.formatMessage({ id: 'emiBased' })}

              </label>
              <select
                disabled={!isCanEditting}
                className={clsx(
                  'form-select form-select-solid mb-6',
                  {
                    'is-invalid': formik.touched.emiBased && formik.errors.emiBased,
                  },
                  {
                    'is-valid': formik.touched.emiBased && !formik.errors.emiBased,
                  }
                )}
                {...formik.getFieldProps('emiBased')}

                aria-label={intl.formatMessage({ id: 'select?' }, {
                  option: intl.formatMessage({ id: 'emiBased' })
                })}>
                <option>{intl.formatMessage({ id: 'select?' }, {
                  option: intl.formatMessage({ id: 'emiBased' })
                })}</option>
                {
                  emiBasedOnSelection.map((value) => <option key={value[0]} value={value[0]}>{value[1]}</option>)
                }
                {formik.touched.emiBased && formik.errors.emiBased && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.emiBased}</span>
                  </div>
                )}
              </select>
              {formik.touched.emiBased && formik.errors.emiBased && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.emiBased}</span>
                </div>
              )}





              {
                isCreateOpe ? "" : (
                  <div className='row'>
                    {/* installmentDeducation  */}
                    <div className='col-lg-6'>
                      <div className='mb-6'>
                        <label htmlFor='' className='form-label required'>
                          {intl.formatMessage({ id: 'installmentDeducation' })}
                        </label>
                        <input type='text'
                          disabled={true}
                          placeholder={intl.formatMessage({ id: 'installmentDeducation' })}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': formik.touched.installmentDeducation && formik.errors.installmentDeducation,
                            },
                            {
                              'is-valid': formik.touched.installmentDeducation && !formik.errors.installmentDeducation,
                            }
                          )}
                          {...formik.getFieldProps('installmentDeducation')} />
                        {formik.touched.installmentDeducation && formik.errors.installmentDeducation && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.installmentDeducation}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* installmentDeducation  */}
                    {/* amountPaid  */}
                    {isCreateOpe ? "" : <div className='col-lg-6'>
                      <div className='mb-6'>
                        <label htmlFor='' className='form-label required'>
                          {intl.formatMessage({ id: 'amountPaid' })}
                        </label>
                        <input type='number'
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={true}
                          placeholder={intl.formatMessage({ id: 'amountPaid' })}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': formik.touched.amountPaid && formik.errors.amountPaid,
                            },
                            {
                              'is-valid': formik.touched.amountPaid && !formik.errors.amountPaid,
                            }
                          )}
                          {...formik.getFieldProps('amountPaid')} />
                        {formik.touched.amountPaid && formik.errors.amountPaid && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.amountPaid}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    }
                    {/* amountPaid  */}
                    {/* amountToPay  */}
                    <div className='col-sm-6'>
                      <div className='mb-6'>
                        <label htmlFor='' className='form-label required'>
                          {intl.formatMessage({ id: 'amountToPay' })}
                        </label>
                        <input type='number'
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={true}
                          placeholder={intl.formatMessage({ id: 'amountToPay' })}
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': formik.touched.amountToPay && formik.errors.amountToPay,
                            },
                            {
                              'is-valid': formik.touched.amountToPay && !formik.errors.amountToPay,
                            }
                          )}
                          {...formik.getFieldProps('amountToPay')} />
                        {formik.touched.amountToPay && formik.errors.amountToPay && (
                          <div className='fv-plugins-message-container'>
                            <span role='alert'>{formik.errors.amountToPay}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* amountToPay */}

                  </div>
                )
              }
            </div>


            <div className='col-lg-6'>
              <h5 className='mb-4 mt-4 text-primary'>   {intl.formatMessage({ id: 'dates' })}</h5>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='mb-6'>
                    <label htmlFor='' className='form-label required'>
                      {intl.formatMessage({ id: 'requestDate' })}
                    </label>
                    <input
                      disabled={!isCanEditting}
                      type='date'
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.requestDate && formik.errors.requestDate,
                        },
                        {
                          'is-valid': formik.touched.requestDate && !formik.errors.requestDate,
                        }
                      )}
                      {...formik.getFieldProps('requestDate')} />
                    {formik.touched.requestDate && formik.errors.requestDate && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.requestDate}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-sm-12'>
                  <div className='mb-6'>
                    <label htmlFor='' className='form-label required'>
                      {intl.formatMessage({ id: 'loanPaymentStartDate' })}
                    </label>
                    <input
                      disabled={!isCanEditting}
                      type='date' className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.loanPaymentStartDate && formik.errors.loanPaymentStartDate,
                        },
                        {
                          'is-valid': formik.touched.loanPaymentStartDate && !formik.errors.loanPaymentStartDate,
                        }
                      )}
                      {...formik.getFieldProps('loanPaymentStartDate')} />
                    {formik.touched.loanPaymentStartDate && formik.errors.loanPaymentStartDate && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.loanPaymentStartDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-sm-12'>
                  {/* check if by duraction */}
                  {formik.values.emiBased == "amount" ? <div className='mb-6'>
                    <label htmlFor='' className='form-label required'>
                      {intl.formatMessage({ id: 'deductionAmount' })}
                    </label>
                    <input type='number'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }

                      }}

                      disabled={!isCanEditting}
                      placeholder={intl.formatMessage({ id: 'deductionAmount' })}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.deductionAmount && formik.errors.deductionAmount,
                        },
                        {
                          'is-valid': formik.touched.deductionAmount && !formik.errors.deductionAmount,
                        }
                      )}
                      {...formik.getFieldProps('deductionAmount')}

                    />
                    {formik.touched.deductionAmount && formik.errors.deductionAmount && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.deductionAmount}</span>
                      </div>
                    )}
                  </div> : <div className='mb-6'>
                    <label htmlFor='' className='form-label required'>
                      {intl.formatMessage({ id: 'paymentDuratuin' })}
                    </label>
                    <input type='number'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }

                      }}

                      disabled={!isCanEditting}
                      placeholder={intl.formatMessage({ id: 'paymentDuratuin' })}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.paymentDuratuin && formik.errors.paymentDuratuin,
                        },
                        {
                          'is-valid': formik.touched.paymentDuratuin && !formik.errors.paymentDuratuin,
                        }
                      )}
                      {...formik.getFieldProps('paymentDuratuin')}

                    />
                    {formik.touched.paymentDuratuin && formik.errors.paymentDuratuin && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.paymentDuratuin}</span>
                      </div>
                    )}
                  </div>}
                  {/* check if by duraction */}
                </div>
                <div className='col-sm-12'>
                  {isCreateOpe ? "" : <div className='mb-6'>
                    <label htmlFor='' className='form-label required'>
                      {intl.formatMessage({ id: 'loanPaymentEndDate' })}
                    </label>
                    <input type='date'
                      disabled={!isCanEditting}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.loanPaymentEndDate && formik.errors.loanPaymentEndDate,
                        },
                        {
                          'is-valid': formik.touched.loanPaymentEndDate && !formik.errors.loanPaymentEndDate,
                        }
                      )}
                      {...formik.getFieldProps('loanPaymentEndDate')} />
                    {formik.touched.loanPaymentEndDate && formik.errors.loanPaymentEndDate && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.loanPaymentEndDate}</span>
                      </div>
                    )}
                  </div>
                  }
                </div>
              </div>
            </div>



            {/*  B Check if form for create */}
            {isCreateOpe ? "" : <div className='col-sm-12'>
              <h5 className='mb-3 mt-10 text-primary'>{intl.formatMessage({ id: 'extraInformation' })}</h5>

              {/* Tabs */}
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a
                    className='nav-link fw-bolder active'
                    data-bs-toggle='tab'
                    href='#kt_tab_pane_4'
                  >
                    {intl.formatMessage({ id: 'description' })}
                  </a>
                </li>


                <li className='nav-item'>
                  <a className='nav-link fw-bolder' data-bs-toggle='tab' href='#kt_tab_pane_7'>
                    {intl.formatMessage({ id: 'extraInformation' })}
                  </a>
                </li>
              </ul>
              {/* Tabs */}


              {/* Tabs Data */}
              <div className='tab-content mb-6' id='myTabContent'>
                {/* Decription Tab */}
                <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
                  {(store.loansReducer.selectedLoan ?? new LoanModel()).description}
                </div>
                {/* Decription Tab */}


                {/* Info Tab */}


                <div className='tab-pane fade' id='kt_tab_pane_7' role='tabpanel'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='row'>
                        <div className='col-sm-12 my-5'>
                          <h6>
                            {intl.formatMessage({ id: 'createdBy' })}:
                            <span className='text-muted fw-lighter ms-5'>   {((store.loansReducer.selectedLoan ?? new LoanModel()).createdBy ?? [])[1] ?? "-"}</span>
                          </h6>
                        </div>
                        <div className='col-sm-12 '>
                          <h6>
                            {intl.formatMessage({ id: 'lastUpdatedBy' })}:
                            <span className='text-muted fw-lighter ms-5'>   {((store.loansReducer.selectedLoan ?? new LoanModel()).lastUpdatedBy ?? [])[1] ?? "-"}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='row'>
                        <div className='col-sm-12 my-5'>
                          <h6>
                            {intl.formatMessage({ id: 'createdOn' })}:
                            <span className='text-muted fw-lighter ms-5'>   {(store.loansReducer.selectedLoan ?? new LoanModel()).createdOn}</span>
                          </h6>
                        </div>
                        <div className='col-sm-12'>
                          <h6>
                            {intl.formatMessage({ id: 'lastUpdatedOn' })}:
                            <span className='text-muted fw-lighter ms-5'>   {(store.loansReducer.selectedLoan ?? new LoanModel()).lastUpdatedOn}</span>

                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Info Tab */}
              </div>
              {/* Tabs Data */}
            </div>
            }
            {/* E Check if form for create */}

          </div>
        </div>
      </div>
    </form>

  )
}
