import axios from 'axios';
import CurrentUserTypes from '../types/CurrentUserTypes'
import { NavigateFunction } from 'react-router-dom';
import { IntlShape } from 'react-intl'
import UserModel from '../../models/UserModel';
import { AppDispatch } from '../store/Store';
import { ResultStatus } from '../../utils/AppConsts';
import baseUrl from '../../Apis/apis';
import Cookies from 'universal-cookie';
import { domain } from 'process';
import { number } from 'yup';

const login = (domain:string,email: string, password: string, db: string, navigator: NavigateFunction, intl: IntlShape) => {
    console.log("login email:" + email + ", password:" + password + ", db:" + db);
    
    return async (dispatch: AppDispatch) => {
        try {


            const res = await baseUrl.post(domain+'/apiV2/session/authenticate', {
                params: {
                    login: email,
                    password: password,
                    db: db,
                }
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*'
                },
            });


            

            if (res.status == ResultStatus.ok) {
                const result = res.data;
                 var errorMessage: string = ((result['result'] ?? {})['msg']);

                if (errorMessage) {
                    alert(errorMessage);
                } else {

                    var userMap = result['result'];
                    if(userMap){
                        console.log(userMap+'<-----')
                        const cookies = new Cookies();
                        cookies.set('session_id',userMap['session_id']);
                        cookies.set('domain',domain);
                        var user:UserModel =  UserModel.fromJson([{ 
                        }]);
     
                        user.id = userMap['uid']??-1;
                        console.log("musab-mq-"+ JSON.stringify(userMap));
                        
                        cookies.set('userId',user.id,);
                        dispatch({type:CurrentUserTypes.login,data:user});
                        dispatch(getPersonalInformation(user.id,intl));

                    }else{
                        alert(intl.formatMessage({ id: 'checkLogin' }));

                    }
                }
            } else {
                alert(intl.formatMessage({ id: 'tryAgain' }));
            }

        } catch (ex) {
            alert(intl.formatMessage({ id: 'checkInternet' }) + ":-> " + ex);
        }
    }
}


const updateProfile = (name:string,phone: string, identityNumber: string,birthday:string,nationality:string,maritalStatus:string,childrenNumber:string,religion:string,drivingLicense:string,licenseType:string,licenseExpirationDate:string, intl: IntlShape) => {
    
    return async (dispatch: AppDispatch) => {
        try {
            const cookies = new Cookies();
           var sessionId = cookies.get('session_id');
           var serverUrl = cookies.get('domain');
           var userId = cookies.get('userId');
           var employeeId = cookies.get('employeeId');
           console.log(sessionId);
           

           const res = await baseUrl.post(serverUrl+'/apiV2/hr.employee/write',   {
            "params": {
              "id": Number(employeeId),
              "args": {
                "values": {
                    "name":name,
                    "phone":phone,
                    "identification_id":identityNumber,
                    "birthday":birthday,
                    "country_id":Number(nationality),
                    "marital":maritalStatus,
                    "infants":childrenNumber,
                    "religion":religion,
                    "license_number":drivingLicense,
                    "license_type":licenseType,
                    "license_expire_date":licenseExpirationDate,
                }
              }
            }
          }, {
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Access-Control-Allow-Origin':'*',
                   "X-Openerp-Session-Id": sessionId,
               },
           });

           
           if (res.status == ResultStatus.ok) {
               const result = res.data;
                var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

               if (errorMessage) {
                   alert(errorMessage);
                   if(errorMessage.includes("Odoo Session Expired")){
                       dispatch({type:CurrentUserTypes.signout})
                   }
               } else {
                dispatch(getPersonalInformation(userId,intl))
               }
           } else {
               if(intl){
                   alert(intl.formatMessage({ id: 'tryAgain' }));
                 }else{
                     alert( 'Try Again');
                 }
           }
       } catch (ex) {
           console.log("My I MY");
           
           if(intl){
               alert(intl.formatMessage({ id: 'checkInternet' }) + ":-> " + ex);
             }else{
                 alert( 'Check Your Internet ---' + ex  );
             }
       }
    }
}




const getPersonalInformation = (userId:any,intl:  IntlShape | undefined,) => {
    return async (dispatch: AppDispatch) => {
        try {

             const cookies = new Cookies();
            var sessionId = cookies.get('session_id');
            var serverUrl = cookies.get('domain');
            var userId = cookies.get('userId');
            const res = await baseUrl.post(serverUrl+'/apiV2/hr.employee/search_read',  {
                "params": {
                  "args": {
                    "fields": [
                      "image_1920",
                      "name",
                      "birthday",
                      "gender",
                      "phone",
                      "identification_id",
                      "id_expire_date",
                      "job_id",
                      "department_id",
                      "visa_no",
                      "visa_expire",
                      "country_id",
                      "company_id",
                      "iban_number",
                      "employee_type",
                      "address_home_id",
                      "employee_number",
                      "private_email",
                      "marital",
                      "infants",
                      "religion",
                      "license_number",
                      "license_type",
                      "license_expire_date",
                      "can_edit_his_profile"
                  
                    ],
                    "domain": [
                      ["user_id", "=", Number(userId??"-1")]
                    ]
                  }
                }
              }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*',
                    "X-Openerp-Session-Id": sessionId,
                },
            });

            
            if (res.status == ResultStatus.ok) {
                const result = res.data;
                 var errorMessage: string = ((result['error'] ?? {})['data']??{})['message'];

                if (errorMessage) {
                    alert(errorMessage);
                    if(errorMessage.includes("Odoo Session Expired")){
                        dispatch({type:CurrentUserTypes.signout})
                    }
                } else {
                    var myData = (result['result'] ?? []) as any[];
                    if (myData) {
                        console.log('======================');
                        console.log(JSON.stringify(myData));
                        console.log('======================');
                        
                        var user = UserModel.fromJson(myData[0]);
                        user.id = userId;
                        cookies.set('employeeId',result['result'][0]['id']);
                        cookies.set('companyId',((user.company ?? []).at(0)??"-1"));

                        dispatch({type:CurrentUserTypes.login,data:user});
                    } else {
                        if(intl){
                          alert(intl.formatMessage({ id: 'tryAgain' }));
                        }else{
                            alert( 'Try Again');
                        }
                    }
                    
                }
            } else {
                if(intl){
                    alert(intl.formatMessage({ id: 'tryAgain' }));
                  }else{
                      alert( 'Try Again');
                  }
            }
        } catch (ex) {
            console.log("My I MY");
            
            if(intl){
                alert(intl.formatMessage({ id: 'checkInternet' }) + ":-> " + ex);
              }else{
                  alert( 'Check Your Internet ---' + ex  );
              }
        }
    }
}


export const CurrerUserActions = {
    login,
    getPersonalInformation,
    updateProfile
};